.tips {
    position: absolute;
    top: 15px;
    left: 19px;
    font-size: var(--font-size-xl);
    font-weight: 600;
    font-family: var(--font-inter);
  }
  .your-profile-is {
    margin: 0;
    padding-left: var(--padding-2xl);
  }
  .your-profile-is-container {
    position: absolute;
    top: 50px;
    left: 19px;
  }
  .describe-yourself-in {
    margin-bottom: 0;
  }
  .vacancy-and-job {
    margin: 0;

    padding-left: 15px;
  }
  .create-a-good-container,
  .describe-yourself-in-container {
    position: absolute;
    top: 80px;
    left: 19px;
    display: inline-block;
    width: 470px;
  }
  .create-a-good-container {
    top: 144px;
    width: 476px;
  }
  .tips-overlay {
  
    background-color: rgba(255, 207, 142, 0.5);
    border: 1px solid var(--color-black);
    box-sizing: border-box;
    width: 506px;
    height: 270px;
    overflow: hidden;
    mix-blend-mode: darken;
    text-align: left;
    font-size: var(--font-inherit);
    color: var(--color-black);
    font-family: var(--font-inherit);
  }
  .create-a-good-container2 {
    top: 144px;
    width: 476px;
  }
  .create-a-good-container2,
  .describe-yourself-in-container2 {
    position: absolute;
    top: 210px;
    left: 19px;
    display: inline-block;
    width: 470px;
  }
  .vacancy-and-job2 {
    margin: 0;

    padding-left: 15px;
  }
  .your-profile-is {
    margin: 0;
    padding-left: var(--padding-2xl);
  }
  .detail-left{
    width: 50%;
  }
  .detail-right{
    width: 50%;
  }


  @media only screen and (max-width:1528px){
 
    .detail-left{
      width: 52%;
    }
    .detail-right{
      width: 48%;
    }
  }