.form-select{
    width: 250px;
    box-shadow:1px 1px 1px 1px #888888;
    height: 45px;
    margin-top: 10px;
    border-radius: 8px;
}
.the-professional-profile {
  position: absolute;
  top: 31px;
  left: 40px;
  font-weight: 500;
  color: var(--color-black);
}
.save-1 {
  position: relative;
  font-weight: 500;
}
.savebutton-1,
.savebutton1-1 {
  position: absolute;
  top: 199px;
  border-radius: var(--br-mini);
  box-sizing: border-box;
  width: 150px;
  height: 45px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-58xl);
  text-align: center;
}
.savebutton-1 {
  left: 110px;
  background-color: var(--color-darkslateblue);
  color: var(--color-white);
}
.savebutton1-1 {
  left: 560px;
  border: 1px solid var(--color-darkslateblue);
  color: var(--color-darkslateblue);
}
.email,
.emailaddress-child {
  position: absolute;
  left: 0;
  width: 400px;
}
.email {
  color: #000;
  top: 0;
  display: inline-block;
}
.emailaddress-child {
  top: 20px;
  border-radius: var(--br-mini);
  border: 1px solid var(--color-gainsboro-100);
  box-sizing: border-box;
  height: 60px;
  color: #000;
}
.emailaddress,
.emailaddress1 {
  position: absolute;
  top: 86px;
  left: 40px;
  width: 255px;
  height: 80px;
  font-size: var(--font-size-base);
}
.emailaddress1 {
  left: 525px;
}
.linkedinpopup {
  background-color: var(--color-white);
  width: 820px;
  height: 275px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-lightslategray);
  font-family: var(--font-inter);
}
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
 
  --font-inter: Inter;
  --font-montserrat: Montserrat;
  --font-poppins: Poppins;


  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-mini: 15px;
  --font-size-xl: 20px;


  --color-white: #fff;
  --color-gainsboro-100: #e3e4e8;
  --color-gainsboro-200: #d9d9d9;
  --color-lightslategray: #888ea2;
  --color-darkslateblue: #314ca3;
  --color-black: #000;
  --color-darkslategray: #333;
  --color-dimgray: #666;


  --gap-12xs: 1px;
  --gap-xl: 20px;


  --padding-mini: 15px;
  --padding-58xl: 77px;
  --padding-mid: 17px;


  --br-mini: 15px;
  --br-5xs: 8px;
  --br-xl: 20px;
  --br-3xs: 10px;
} */
.the-professional-profile {
  position: absolute;
  top: 31px;
  left: 40px;
  font-weight: 500;
  text-align: left;
}
.save {
  position: relative;
  font-weight: 500;
}
.savebutton,
.savebutton1 {
  position: absolute;
  top: 115px;
  border-radius: var(--br-mini);
  box-sizing: border-box;
  width: 150px;
  height: 45px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-58xl);
}
.savebutton {
  left: 600px;
  background-color: var(--color-darkslateblue);
  color: var(--color-white);
}
.savebutton1 {
  left: 410px;
  border: 1px solid var(--color-darkslateblue);
  color: var(--color-darkslateblue);
}
.resume {
  background-color: var(--color-white);
  width: 800px;
  height: 200px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  font-family: var(--font-inter);
  border-radius: 16px;
}
