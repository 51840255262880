.index {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
.delete-confirm{
  background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
  
  .index .group-wrapper {
    height: 148px;
    overflow: hidden;
    width: 302px;
  }
  
  .index .group {
    height: 148px;
    width: 304px;
  }
  
  .index .overlap {
    background-color: #f7f4f8;
    border: 1px solid;
    
    border-radius: 5px;
    height: 148px;
    position: relative;
    width: 302px;
  }
  
  .index .overlap-group-wrapper {
    height: 29px;
    left: 25px;
    position: absolute;
    top: 100px;
    width: 90px;
  }
  
  .index .overlap-group {
    background-color: #d03329;
    border-radius: 8px;
    height: 29px;
    position: relative;
    width: 88px;
  }
  
  .index .save {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 500;
    left: 30px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 6px;
  }
  
  .index .overlap-wrapper {
    height: 29px;
    left: 173px;
    position: absolute;
    top: 100px;
    width: 90px;
  }
  
  .index .save-wrapper {
    background-color: #349765;
    border-radius: 8px;
    height: 29px;
    position: relative;
    width: 88px;
  }
  
  .index .text-wrapper {
    color: #ffffff;
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: 500;
    left: 32px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 6px;
  }
  
  .index .are-you-sure-to-turn {
    color: #000000;
    font-family: "Inter", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 39px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 19px;
  }

  .checkbox-wrapper-22 .switch {
    display: inline-block;
    height: 34px;
    position: relative;
    width: 60px;
  }

  .checkbox-wrapper-22 .switch input {
    display:none;
  }

  .checkbox-wrapper-22 .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }

  .checkbox-wrapper-22 .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 26px;
  }

 .checkbox-wrapper-22 input:checked + .slider {
    background-color: #314CA3;
  }

  .checkbox-wrapper-22 input:checked + .slider:before {
    transform: translateX(26px);
  }

  .checkbox-wrapper-22 .slider.round {
    border-radius: 34px;
  }

  .checkbox-wrapper-22 .slider.round:before {
    border-radius: 50%;
  }

  @media screen and (max-width: 1700px) and (min-width: 1400px) {
    .checkbox-wrapper-22 .switch {
        height: 26px;
        width: 52px;
    }

    .checkbox-wrapper-22 .slider {
        background-color: #ccc;
    }

    .checkbox-wrapper-22 .slider:before {
        height: 19px;
        width: 19px;
    }
}

@media screen and (max-width: 1400px) and (min-width: 1222px) {
    .checkbox-wrapper-22 .switch {
        height: 27px;
        width: 57px;
    }

    .checkbox-wrapper-22 .slider {
        background-color: #ccc;
    }

    .checkbox-wrapper-22 .slider:before {
        height: 19px;
        width: 19px;
    }

 

  
 }


 @media screen and (max-width: 1400px) and (min-width: 1222px){

  .checkbox-wrapper-22 .switch {
    display: inline-block;
    height: 27px;
    position: relative;
    width: 57px;
  }

  

  .checkbox-wrapper-22 .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }

  .checkbox-wrapper-22 .slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 19px;
    left: 4px;
    position: absolute;
    transition: .4s;
    width: 19px;
  }

 

  .checkbox-wrapper-22 .slider.round:before {
    border-radius: 50%;
  }

  
 }