.start-interview2 {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.start-interview2 .group-wrapper {
  height: 558px;
  width: 820px;
  overflow: hidden;
}
.start-interview2 .group-wrapper1 {
  height: 1158px;
  width: 820px;
  overflow: hidden;
}
.start-interview2 .group-wrapper3 {
  height: 800px;
  width: 820px;
  overflow: hidden;
}

.start-interview2 .group-wrapper4 {
  height: 650px;
  width: 50%;
  overflow: hidden;
}
.start-interview2 {
  padding: 10px;
}

.start-interview2.group { 
  width: 820px;
}

.start-interview2 .overlap {

  border-radius: 25px;
  height: 170px;
  position: relative;
}

.start-interview2 .overlap1 {

  border-radius: 25px;
  height: 170px;
  position: relative;
  left:-500px; 
  top: -60px;
}


.start-interview2 .what-type-of {
  height: 209px;
  left: 57px;
  position: absolute;
  top: 89px;
  width: 308px;
}

.start-interview2 .listbox-title-instance {
  left: 14px !important;
  position: absolute !important;
  top: 0 !important;
  width: 280px !important;
}

.start-interview2 .overlap-group {
  height: 60px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 308px;
}

.start-interview2 .listbox-main {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 14px #0000001a;
  height: 60px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 280px;
}

.start-interview2.chevron {
  height: 6px;
  left: 247px;
  position: absolute;
  top: 27px;
  width: 11px;
}

.start-interview2 .placeholder-text-instance {
  left: 16px !important;
}

.start-interview2 .clip-list {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 308px;
}

.start-interview2 .div {
  height: 289px;
  left: 500px;
  position: absolute;
  top: 82px;
  width: 308px;
}

.start-interview2 .select-one-from {
  height: 289px;
  left: 0;
  position: absolute;
  top: 0;
  width: 308px;
}

.start-interview2 .overlap-2 {
  height: 261px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 308px;
}

.start-interview2 .clip-list-2 {
  height: 261px;
  left: 0;
  position: absolute;
  top: 0;
  width: 308px;
}

.start-interview2 .save-button {
  all: unset;
  align-items: center;
  background-color: #314ca3;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  left: 144px;
  overflow:visible;
  padding: 15px 77px;
  position: absolute;
  top: 193px;
  width: 150px;
}

.start-interview2 .save {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;

  margin-left: -23.5px;

  position: relative;
  text-align: center;
  width: fit-content;
}

.start-interview2 .text-wrapper {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 71px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 25px;
}

.index {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.index .div {
  background-color: #ffffff;
  height: 1095px;
  /* position: relative; */
  width: 820px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: absolute;
  top: 132px;
  
}

.index .text-wrapper {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
}

.index .first-name {
  /* height: 142px; */
  /* left: 53px;
  position: absolute;
  top: 83px; */
  width: 709px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.index .your-name {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  /* left: 0; */
  letter-spacing: 0;
  line-height: normal;
  /* position: absolute;
  top: -1px;
  white-space: wrap; */
  width: 707px;
  
}


.index .frame {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e3e4e8;
  border-radius: 15px;
  height: 100px;
  /* left: 0;
  position: absolute;
  top: 42px; */
  width: 709px;
}

.index .charlene-reed {
  color: #182651;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  /* left: 1px; */
  letter-spacing: 0;
  line-height: normal;
  /* position: absolute;
  top: 1px; */
  width: 705px;
  height: 95px;
  border: none;
  border-radius: 15px;
}

.index .first-name-2 {
  /* height: 142px;
  left: 56px;
  position: absolute;
  top: 240px; */
  width: 709px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.index .first-name-3 {
  /* height: 142px;
  left: 59px;
  position: absolute;
  top: 397px; */
  width: 709px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.index .first-name-4 {
  /* height: 142px;
  left: 62px;
  position: absolute;
  top: 554px; */
  width: 709px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.index .first-name-5 {
  /* height: 142px;
  left: 65px;
  position: absolute;
  top: 711px; */
  width: 709px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.index .save-button {
  align-items: center;
  background-color: #314ca3;
  border-radius: 15px;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  left: 624px;
  overflow: hidden;
  padding: 15px 77px;
  position: absolute;
  top: 900px;
  width: 150px;
  
  
}

 .getadvice-button {
  align-items: center;
  background-color: #314ca3;
  border-radius: 15px;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  /* left: 624px; */
  overflow: hidden;
  padding: 15px 77px;
  /* position: absolute; */
  /* top: 900px; */
  width: 150px;
  position: relative;
  left: 278px;
  top: 30px;
  
  
}

.index .save1 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.5px;
  margin-left: -49.5px;
  margin-right: -49.5px;
  margin-top: -4.5px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.index .scroll-bar {
  background-color: #438ffe;
  border-radius: 49px;
  height: 523px;
  left: 815px;
  position: absolute;
  top: 100px;
  transform: rotate(180deg);
  width: 5px;
}


.index3 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.index3 .div {
  background-color: #ffffff;
  height: 722px;
  position: relative;
  width: 820px;
}

.index3 .text-wrapper {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
}

.index3 .first-name {
  height: 492px;
  left: 53px;
  position: absolute;
  top: 83px;
  width: 709px;
}

.index3 .your-name {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
  white-space: wrap;
  width: 378px;
}


.index3 .frame {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e3e4e8;
  border-radius: 15px;
  height: 450px;
  left: 0;
  position: absolute;
  top: 42px;
  width: 709px;
}

.index3 .charlene-reed {
  color: #182651;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 0.5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0.5px;
  width: 705px;
  border: none;
  border-radius: 15px;
}

.index3 .save-button {
  align-items: center;
  background-color: #314ca3;
  border-radius: 15px;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  left: 330px;
  overflow: hidden;
  padding: 15px 77px;
  position: absolute;
  top: 646px;
  width: 150px;
}

.index3 .save {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.5px;
  margin-left: -64px;
  margin-right: -64px;
  margin-top: -4.5px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.index3 .save-wrapper {
  align-items: center;
  background-color: #314ca3;
  border-radius: 15px;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  left: 53px;
  overflow: hidden;
  padding: 15px 77px;
  position: absolute;
  top: 646px;
  width: 150px;
}

.index3 .save-2 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.5px;
  margin-left: -18px;
  margin-right: -18px;
  margin-top: -4.5px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.index3 .group {
  height: 50px;
  left: 572px;
  position: absolute;
  top: 33px;
  width: 192px;
}

.index3 .overlap-group {
  background-color: #314ca3;
  border-radius: 15px;
  height: 50px;
  position: relative;
  width: 190px;
}

.index3 .save-3 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 31px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.index3 .octicon-download {
  height: 21px;
  left: 133px;
  position: absolute;
  top: 14px;
  width: 23px;
}


.index4 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.index4 .div {
  background-color: #ffffff;
  height: 617px;
  position: relative;
  width: 820px;
  
}

.index4 .frame {
  border: 1px solid;
  border-color: #e3e4e8;
  border-radius: 15px;
  height: 49px;
  left: 56px;
  overflow: hidden;
  position: absolute;
  top: 537px;
  width: 709px;
}

.index4 .text-wrapper {
  color: #888ea2 !important;
  font-family: "Inter", Helvetica;
  font-size: 15px !important;
  font-weight: 400 !important;
  left: 1px !important;
  letter-spacing: 0 !important;
  line-height: normal;
  position: absolute;
  white-space: wrap ;
  border: none;
  top: 1px !important;
  border-radius: 15px !important;
  height: 45px !important;
  width: 705px;
}

.index4 .mingcute-send-line {
  height: 24px;
  left: 668px;
  position: absolute;
  top: 13px;
  width: 24px;
}

.index4 .group {
  height: 26px;
  left: 56px;
  position: absolute;
  top: 103px;
  width: 57px;
  
}

.index4 .text-wrapper-2 {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 700;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 7px;
  white-space: wrap;
}

.index4 .profile-picture {
  align-items: flex-start;
  display: inline-flex;
  gap: 4.33px;
  left: 0;
  position: absolute;
  top: 0;
}

.index4 .ellipse {
  height: 26px;
  object-fit: cover;
  position: relative;
  width: 26px;
}

.index4 .group-2 {
  height: 24px;
  left: 56px;
  position: absolute;
  top: 42px;
  width: 112px;
}

.index4 .text-wrapper-3 {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 700;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 6px;
  white-space: wrap;
}

.index4 .ri-robot-line {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.index4 .group-3 {
  height: 24px;
  left: 56px;
  position: absolute;
  top: 166px;
  width: 112px;
}

.index4 .scroll-bar {
  background-color: #438ffe;
  border-radius: 49px;
  height: 200px;
  left: 815px;
  position: absolute;
  top: 168px;
  transform: rotate(180deg);
  width: 5px;
}

.index4 .your-name {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 500;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 138px;
  width: 100vw !important;
  /* background-color: red; */
}

.index4 .p {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 500;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 75px;
  /* background-color: red; */
}

.index4 .your-name-2 {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 500;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 199px;
  width: 100vw !important;
  /* background-color: red; */
}

.your-name{
  white-space: normal;
  width: 707px;
}
.save-button{
  position: relative;
  left: 278px;
  top: 35px;
}

