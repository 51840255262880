
.out-container{
  background-color: #f4f6f9;
  padding: 30px;
 
}
.head-container{
  display: flex;
  flex-direction: row;
}
.robo{
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 44px;
  top: 45px;
  align-items: center;
  gap: 10px;
}
.robo-img{
  width: 81px;
  height: 73px;
}
.robo-text{
  font-size: 20px;
  color: #5ac6c8;
  position: relative;
  top: 3px;
  font-weight: 600
}
.in-container{
    padding: 0.75rem; 
    background-color: rgba(255, 255, 255, 0.7); 
    border-width: 2px; 
    border-color: rgba(156, 163, 175, 0.33);
    border-style: solid; 
    display: flex;
    flex: 1; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    gap: 50px;
    border-radius: 10px; 
    width: 100%; 
    padding: 50px;
    background-color: white;
    padding-top: 30px ;
    /* margin: 10px; */
}
.heading{
  font-size: large;
  display: flex;
  flex-direction:row;
  gap: 20px;
  width: fit-content;
  font-size: 40px;

}
.head-img{
  width: 50px;
  height: 50px;
  color: rgb(57, 55, 55);

}
.head-text{
  color: rgb(57, 55, 55);
  /* font-size: 50px; */
}
.template-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  
  .template-item {
    width: 100%;
    height: 100%;
    border: 2px solid rgb(208, 206, 206);
    border-radius: 8px;
    box-shadow: 2px 2px 5px grey
  }
  
  .template-item img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    padding: 20px;
  }
  