.bg2 {
  background-color: #438ffe;
}

.bg3 {
  background-color: #d9d9d9;
  color: #d9d9d9;
}
.info-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  color: grey;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 200px;
  top: 100%;
  left: 0;
  z-index: 1;
}

.info-button:hover .info-content {
  display: block;
}
