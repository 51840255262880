.navbar {
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index:1001;
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    margin-right: 30px;
  }
  
  .nav-menu {
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index:1000;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
   
  }
  
  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 45px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: #1a83ff;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #e8e9f0;
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  
  span {
    margin-left: 16px;
  }