.hoverable-text {
    position: relative;
  }
  
  .hoverable-text:hover::after {
    /* content: "Tooltip text or interactive feature"; */
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  