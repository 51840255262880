.line-prof{
  background-color: grey;

}
.text-wrapper-3 {
  color: #00000080;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  white-space: wrap;
}
.text-wrapper-2 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 500;
  left: 43px;
  letter-spacing: 0;
  line-height: normal;
  top: 0;
  white-space: wrap;
  
  
}

.amazon-full-time {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 43px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 23px;
  white-space: wrap;
}

.rectangle {
  background-color: #d9d9d9;
  border-radius: 8px;
  margin-bottom:20px ;
  margin-top: 10px;
  height: 2px;
  width: auto;
  margin-left: 50px;
  margin-right: 20px;

  
}
.bxs-edit {
  height: 35px;
  width: 35px;
  margin-right: 20px;
}
.bxs-delete {
  height: 30px;
  width: 30px;
  margin-right: 20px;
}

.work-edit-input{
  padding: 15px;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  padding: auto;
}
.work-edit-input{
  background-color: rgb(240, 235, 235);
  border-radius: 15px;
  padding-left: 30px;
}