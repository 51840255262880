.job-seekers2 {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: calc(50% - 63.5px);
  line-height: 24px;
  z-index: 0;
}

.toggle-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-seeker1 {
  width: 129px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
.company3 {
  position: relative;
  line-height: 24px;
}
.company2,
.right-fields1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.company2 {
  width: 89px;
  height: 24px;
  align-items: center;
  padding: 0 var(--padding-11xs);
  box-sizing: border-box;
}
.right-fields1 {
  position: absolute;
  top: 32%;
  right: 73px;
  align-items: flex-start;
  padding: var(--padding-3xs) var(--padding-mid);
  gap: var(--gap-3xs);
}
.recruiter-robo-logo-3-1-icon1 {
  /* position: absolute;
  top: 17px;
  left: 17px;
  width: 76px;
  height: 82px;
  object-fit: cover; */
  position: absolute;
  top: 19px;
  left: 25px;
  width: 63px;
  height: 77px;
  object-fit: cover;
}
.website-name-frame-parent1 {
  position: absolute;
  top: 0;
  left:4px;
  width: 330px;
  height: 111px;
  font-size: var(--font-size-5xl);
  /* color: var(--color-gray-100); */
  font-family: var(--font-poppins);
}
.logo-frame1,
.website-name1 {
  position: absolute;
  cursor: pointer;
}
.logo-frame1 {
  top: 0;
  left: 0;
  height: 118px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.website-name1 {
  top: 12px;
  left: 10.5px;
  line-height: 135.5%;
  font-weight: 600;
}
.website-name-frame1 {
  /* position: absolute;
  top: 37%;
  left: 126px;
  width: 203px;
  height: 56.3px;
  overflow: hidden;
  line-height: 135.5%;
  font-weight: 600;  */
  position: absolute;
  top: 53.5px;
  left: 81px;
  width: 203px;
  height: 53px;
  overflow: hidden;
  font-size: 24px;

}
.logo-frame-parent {
  position: absolute;
  top: 4px;
  left: 46px;
  width: 329px;
  height: 118px;
  font-size: var(--font-size-5xl);
  color: var(--color-gray-100);
  font-family: var(--font-poppins);
}
.product2 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 24px;
}
.product-tag1 {
  position: absolute;
  top: 8px;
  left: 0;
  width: 70px;
  height: 24px;
  cursor: pointer;
  text-align: center;
}
.pricing2 {
  position: absolute;
  top: 0;
  left: 18px;
  line-height: 24px;
}
.about-us-tag1,
.pricing-tag1 {
  position: absolute;
  top: 8px;
  left: 95px;
  width: 98px;
  height: 24px;
  cursor: pointer;
}
.about-us-tag1 {
  left: 218px;
  width: 100px;
}
.left-fields1 {
  position: absolute;
  top: 32%;
  left: calc(50% - 140px);
  width: 298px;
  height: 40px;
  text-align: left;
}
.header1,
.website-header1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 137px;
}
.header1 {
  width: calc(100% + 2px);
  right: -2px;
  background-color: var(--color-white);
}
.website-header1 {
  width: 100%;
  right: 0;
}
.job-seekers3,
.recruiters1 {
  position: absolute;
  top: 46px;
  left: 133px;
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 176.7px;
  height: 23.1px;
}
.recruiters1 {
  left: 664px;
}
.subheading-container1 {
  position: relative;
  width: 1012px;
  height: 105.1px;
  overflow: hidden;
  flex-shrink: 0;
}
.heading-69,
.our-all-the1 {
  position: relative;
  line-height: 34.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 316px;
  height: 68px;
  flex-shrink: 0;
}
.our-all-the1 {
  font-size: var(--font-size-lg-5);
  line-height: 30.8px;
  font-family: var(--font-rubik);
  width: 317px;
  height: 53px;
}
.text-container12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-17xl) var(--padding-5xs);
  gap: var(--gap-4xl);
}
.everything-you-require1,
.heading-610 {
  position: relative;
  line-height: 34.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;
  height: 48px;
  flex-shrink: 0;
}
.everything-you-require1 {
  font-size: var(--font-size-lg-5);
  line-height: 30.83px;
  font-family: var(--font-rubik);
  width: 304px;
  height: 53px;
}
.job-seeker-column1,
.text-container13 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.text-container13 {
  /* width: 325px; */
  height: 180px;
    align-items: center;
    padding: var(--padding-17xl) var(--padding-mini);
    box-sizing: border-box;
    position: relative;
    top: 60px;
    gap: var(--gap-4xl);
}
.job-seeker-column1 {
  position: absolute;
  top: -0.1px;
  left: calc(50% - 444.5px);
  align-items: flex-start;
}
.heading-611,
.no-waiting-for1 {
  position: relative;
  line-height: 34.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 321px;
  height: 32px;
  flex-shrink: 0;
}
.no-waiting-for1 {
  font-size: var(--font-size-lg-5);
  line-height: 30.83px;
  font-family: var(--font-rubik);
  width: 318px;
  height: 53px;
}
.text-container14 {
  width: 349px;
  height: 206px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-17xl) var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-4xl);
}
.heading-612,
.in-house-candidates1 {
  position: relative;
  line-height: 34.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 318px;
  height: 44px;
  flex-shrink: 0;
}
.in-house-candidates1 {
  font-size: var(--font-size-lg-5);
  line-height: 30.83px;
  font-family: var(--font-rubik);
  width: 330px;
  height: 68px;
}
.recruiter-column1,
.text-container15 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.text-container15 {
  width: 349px;
  height: 180px;
  align-items: center;
  padding: 64px var(--padding-mini) var(--padding-17xl);
  box-sizing: border-box;
  gap: 11px;
}
.recruiter-column1 {
  position: absolute;
  top: -0.1px;
  left: calc(50% + 78px);
  height: 396px;
  align-items: flex-start;
}
.content-rows-container1 {
  position: relative;
  width: 1010px;
  height: 393px;
  font-size: 25.43px;
  color: var(--color-black);
  font-family: var(--font-plus-jakarta-sans);
}
.subheading-container-group {
  position: absolute;
  top: 161px;
  left: calc(50% - 466.5px);
  width: 1010px;
  height: 521px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1px;
  box-sizing: border-box;
  gap: var(--gap-4xl);
}
.heading-212,
.yellow-text1 {
  position: absolute;
  top: 52px;
  height: 49px;
}
.yellow-text1 {
  left: calc(50% - 287px);
  border-radius: 0 var(--br-xl) 0 0;
  background-color: var(--color-goldenrod);
  width: 279px;
}
.heading-212 {
  left: calc(50% - 266px);
  line-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 543px;
}
.heading-container4 {
  position: absolute;
  top: 17px;
  left: calc(50% - 345.5px);
  width: 610px;
  height: 121px;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.ball2svg-icon3 {
  position: relative;
  width: 103px;
  height: 103px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.yellow-ball-image-container1 {
  position: absolute;
  top: 14px;
  left: calc(50% + 284.5px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) 9px;
}
.content-container13 {
  position: absolute;
  top: 26px;
  left: calc(50% - 592px);
  width: 1181px;
  height: 684px;
  overflow: hidden;
}
.whyus1 {
  position: absolute;
  width: 100%;
  top: 11630px;
  right: 0;
  left: 0;
  /* background-color: var(--color-gray-300); */
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  height: 763px;
  font-size: var(--font-size-lg);
  color: var(--color-dodgerblue-100);
  font-family: var(--font-dm-sans);
}
.image-2-icon1 {
  position: relative;
  width: 507px;
  height: 704px;
  object-fit: cover;
}
.resume-21 {
  position: absolute;
  top: 0;
  left: calc(50% - 253.5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.resume-2-wrapper {
  position: absolute;
  top: 0;
  left: calc(50% - 531px);
  width: 507px;
  height: 704px;
}
.image-3-icon1 {
  position: relative;
  width: 506px;
  height: 704px;
  object-fit: cover;
}
.resume-31 {
  position: absolute;
  top: 0;
  left: calc(50% - 253px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.resume-3-wrapper,
.resumetemplates3 {
  position: absolute;
  top: 0;
  left: calc(50% + 25px);
  width: 506px;
  height: 704px;
}
.resumetemplates3 {
  left: calc(50% - 535px);
  width: 1070px;
}
.arrow-container-icon3 {
  position: absolute;
  top: 12.5px;
  left: 173px;
  width: 22.6px;
  height: 23px;
}
.take-me-to3 {
  position: absolute;
  top: 10px;
  left: 9px;
}
.text-container16 {
  position: absolute;
  top: 4px;
  /* left: 25.6px; */
  width: 140px;
  height: 40px;
  overflow: hidden;
}
.moretemplates1 {
  position: absolute;
  top: 758px;
  left: calc(50% - 110px);
  border-radius: var(--br-45xl);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 218.6px;
  height: 48px;
  padding-left: 21px;
  padding-right: 21px;

}
.content-container14 {
  position: absolute;
  top: 312px;
  left: calc(50% - 522px);
  width: 1085px;
  height: 807px;
}
.heading-213 {
  position: relative;
  line-height: 62px;
}
.title-conatiner2 {
  position: absolute;
  top: 146px;
  left: calc(50% - 158px);
  width: 404px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.ball1svg-icon2 {
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.ball-container4 {
  position: absolute;
  top: 84px;
  left: calc(50% - 460px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-lg) var(--padding-2xl);
}
.resumetemplates2 {
  position: absolute;
  width: 100%;
  top: 10444px;
  right: 0;
  left: 0;
  background-color: var(--color-white);
  height: 1186px;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
}
.auto-apply2 {
  position: absolute;
  top: 17px;
  left: 0;
  line-height: 62px;
}
.text-container17 {
  position: relative;
  width: 242px;
  height: 96px;
  overflow: hidden;
  flex-shrink: 0;
}
.heading-container5,
.yellow-badge2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.yellow-badge2 {
  border-radius: 0 var(--br-xl) 0 0;
  background-color: var(--color-goldenrod);
  padding: 0 var(--padding-base);
}
.heading-container5 {
  position: absolute;
  top: 0;
  left: calc(50% - 569.5px);
  padding: var(--padding-5xl) 320px;
  gap: var(--gap-9xl);
}
.q-image-icon1 {
  position: relative;
  border-radius: var(--br-xs);
  width: 100.8px;
  height: 100.8px;
  object-fit: cover;
}
.company1-container1 {
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xs) var(--padding-mini);
}
.linkedin-image-icon1 {
  position: relative;
  width: 100.8px;
  height: 100.8px;
  object-fit: cover;
}
.company2-container1,
.company3-container1,
.company4-container1,
.company5-container1 {
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-smi);
}
.company3-container1,
.company4-container1,
.company5-container1 {
  padding: var(--padding-2xs) var(--padding-lgi);
}
.company4-container1,
.company5-container1 {
  padding: var(--padding-2xs) var(--padding-xs);
}
.company5-container1 {
  padding: var(--padding-2xs) var(--padding-base);
}
.company6-container1 {
  padding: var(--padding-2xs) 0;
}
.company6-container1,
.company7-container1 {
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.rolling-companies1 {
  position: absolute;
  top: 240px;
  left: calc(50% - 595.8px);
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-36xl);
}
.autoapply1 {
  position: absolute;
  width: 100%;
  top: 9964px;
  right: 0;
  left: 0;
  /* background-color: var(--color-gray-400); */
  height: 480px;
}
.video-recruiter-demo-icon2 {
  position: relative;
  width: 840px;
  height: 710px;
  object-fit: cover;
}
.video-container2 {
  position: absolute;
  top: 325px;
  left: calc(50% - 399px);
  width: 840px;
  height: 710px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.ball2svg-icon4 {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.yellow-ball-image-container2 {
  position: absolute;
  top: 933px;
  left: calc(50% + 441px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xl);
}
.ball3svg-icon4 {
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.green-ball-image-container2 {
  position: absolute;
  top: 307px;
  left: calc(50% - 569px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xl) var(--padding-12xl);
}
.heading-214 {
  position: absolute;
  top: 26px;
  left: 24px;
  line-height: 62px;
}
.heading-container6 {
  position: absolute;
  top: 115px;
  left: calc(50% - 237px);
  width: 579px;
  height: 114px;
  overflow: hidden;
}
.take-me-to4 {
  position: relative;
}
.button-text-container2 {
  width: 213px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-mini);
  box-sizing: border-box;
}
.arrow-container-icon4 {
  position: relative;
  width: 22.6px;
  height: 23px;
  top: 10px;
}
.button-container2 {
  position: absolute;
  top: 1158px;
  left: calc(50% - 119px);
  border-radius: var(--br-45xl);
  border: 1px solid var(--color-gray-500);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-11xl);
  gap: var(--gap-xl);
  cursor: pointer;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
}
.bg-ractangle-shapepng-icon2,
.smartjobseekersdemo1 {
  position: absolute;
  width: 100%;
  top: 8678px;
  right: 0;
  left: 0;
  height: 1286px;
}
.bg-ractangle-shapepng-icon2 {
  top: 0;
  max-width: 100%;
  overflow: hidden;
  height: 1107px;
  object-fit: cover;
}
.video-recruiter-demo-icon3 {
  position: relative;
  width: 654.5px;
  height: 666px;
  object-fit: cover;
}
.video-container3 {
  position: absolute;
  top: 26px;
  left: calc(50% - 595.7px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-base);
}
.heading-215,
.watch-this-demo1 {
  position: relative;
  display: flex;
  align-items: center;
}
.heading-215 {
  line-height: 62px;
  font-family: var(--font-montserrat-alternates);
  justify-content: center;
  width: 366px;
  height: 132px;
  flex-shrink: 0;
}
.watch-this-demo1 {
  font-size: var(--font-size-lg);
  line-height: 24px;
  color: var(--color-midnightblue-200);
  text-align: left;
  width: 304px;
}
.take-me-to5 {
  /* position: absolute; */
  top: 12px;
  left: 8px;
}
.button-text-container3 {
  position: absolute;
  top: 11px;
  left: 32px;
  width: 159px;
  height: 24px;
  overflow: hidden;
}
.arrow-container-icon5 {
  position: absolute;
  top: 10px;
  left: 198px;
  width: 22.6px;
  height: 23px;
}
.button-container3 {
  position: relative;
  border-radius: var(--br-45xl);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 275.6px;
  height: 44px;
  font-size: var(--font-size-base);
  color: var(--color-black);
}
.ball-container5,
.content-container16 {
  position: absolute;
  display: flex;
  justify-content: flex-start;
}
.content-container16 {
  top: 26px;
  left: calc(50% + 133px);
  width: 395px;
  flex-direction: column;
  align-items: center;
  padding: 109px var(--padding-2xl);
  box-sizing: border-box;
  gap: 58px;
}
.ball-container5 {
  top: 551px;
  right: 279px;
  overflow: hidden;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--padding-2xl) var(--padding-7xl);
}
.content-container15 {
  position: absolute;
  top: 107px;
  left: calc(50% - 607px);
  width: 1218px;
  height: 762px;
  overflow: hidden;
}
.image-background-container1 {
  position: absolute;
  width: calc(100% + 2px);
  top: 0;
  right: -2px;
  left: 0;
  height: 1107px;
  overflow: hidden;
}
.recruiterdemo1 {
  position: absolute;
  width: 100%;
  top: 7571px;
  right: 0;
  left: 0;
  background-color: var(--color-white);
  height: 1107px;
  font-family: var(--font-inter);
}
.countries-worldwide1,
.heading-216 {
  position: relative;
  line-height: 58.4px;
  display: flex;
  align-items: center;
  width: 78px;
  height: 49px;
  flex-shrink: 0;
}
.countries-worldwide1 {
  font-size: var(--font-size-lg);
  line-height: 40px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-gray-200);
  width: 179.2px;
  height: 24px;
}
.countries-container1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0;
  gap: var(--gap-lg);
}
.k2 {
  position: absolute;
  top: 4px;
  left: 0;
  line-height: 58.4px;
  display: flex;
  align-items: center;
  width: 94.8px;
  height: 49px;
}
.heading-217,
.registered-user1 {
  position: relative;
  width: 148px;
  height: 58px;
}
.registered-user1 {
  font-size: var(--font-size-lg);
  line-height: 40px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
  height: 24px;
  flex-shrink: 0;
}
.users-container1 {
  width: 148px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.k3 {
  position: absolute;
  top: 4px;
  left: 0;
  line-height: 58.4px;
  display: flex;
  align-items: center;
  width: 77.1px;
  height: 49px;
}
.heading-218,
.small-big1 {
  position: relative;
  width: 210px;
  height: 58px;
}
.small-big1 {
  font-size: var(--font-size-lg);
  line-height: 40px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
  width: 195.8px;
  height: 24px;
  flex-shrink: 0;
}
.companies-container1 {
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.content-container17,
.customers-stats-container1 {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-container17 {
  width: 100%;
    flex-direction: row;
    padding: var(--padding-21xl) 0 var(--padding-21xl) 0;
    gap: 148px;
    align-items: center;
    justify-content: center;
}
.customers-stats-container1 {
  position: absolute;
  width: calc(100% + 3.2px);
  top: 1211px;
  right: -3.6px;
  left: 0.4px;
  border-top: 1px solid var(--color-gray-500);
  border-bottom: 1px solid var(--color-gray-500);
  flex-direction: column;
  padding: 55px 0px;
}
.bg-ractangle-shapepng-icon3 {
  position: absolute;
  width: calc(100% + 3px);
  top: 0;
  right: -3px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 1211px;
  object-fit: cover;
}
.ball-container6 {
  position: absolute;
  top: 1004px;
  left: calc(50% - 503px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.arrow-left-blacksvg1 {
  position: absolute;
  right: 70px;
  bottom: 10px;
  width: 20px;
  height: 20px;
}
.heading-613 {
  top: 35px;
  left: -5px;
  line-height: 30px;
  color: var(--color-darkslategray-100);
  width: 108.3px;
  height: 20px;
  font-size: large;
}
.div1,
.heading-510,
.heading-613 {
  position: absolute;
    display: flex;
    align-items: center;
    top: 27px;
    left: 0px;
}
.heading-510 {
  top: -10px;
  left: 0;
  font-size: var(--font-size-lg);
  line-height: 30px;
  width: 309px;
}
.div1 {
  top: 15px;
  left: 523px;
  letter-spacing: 3px;
  line-height: 25px;
  font-weight: 500;
  width: 32.2px;
  height: 20px;
}
.arrow-right-blacksvg-icon1 {
  position: absolute;
  right: 42px;
  bottom: 26px;
  width: 20px;
  height: 20px;
}
.arrow-left-blacksvg-icon1 {
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% + 179.5px);
  width: 20px;
  height: 14.7px;
  overflow: hidden;
}
.divcustomers-name1,
.we-use-smart1 {
  position: absolute;
  top: 359px;
  left: 5px;
  width: 627px;
  height: 62px;
}
.we-use-smart1 {
  top: 7px;
  left: 0;
  line-height: 45px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 589px;
  height: 209px;
}
.p4 {
  position: absolute;
  top: 90px;
  left: 7px;
  width: 630px;
  height: 234px;
  font-size: var(--font-size-5xl);
}
.heading-219,
.heading-511 {
  position: relative;
  line-height: 58.4px;
  display: flex;
  align-items: center;
  width: 389px;
  height: 58px;
  flex-shrink: 0;
}
.heading-511 {
  font-size: var(--font-size-lg);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  height: 24px;
}
.divhappy-customers-title1 {
  position: absolute;
  top: -40px;
  left: calc(50% - 257px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
  font-size: var(--font-size-21xl);
  font-family: var(--font-jost);
}
.divhappy-customer-left1 {
  position: absolute;
  top: 38px;
  left: 10px;
  width: 622px;
  height: 422px;
}
.emojisvg-icon1 {
  position: relative;
  width: 435px;
  height: 411px;
  overflow: hidden;
  flex-shrink: 0;
}
.emojisvg1 {
  position: absolute;
  top: calc(50% - 182.5px);
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.pseudo5 {
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: var(--br-30xl);
  background-color: var(--color-whitesmoke);
  width: 98px;
  height: 98px;
}
.happy-customers1png-icon1 {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  border-radius: var(--br-21xl);
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.divhalfcircle1,
.divslider-img4 {
  position: absolute;
  border-radius: var(--br-32xl);
}
.divslider-img4 {
  top: 0;
  left: 176px;
  width: 102px;
  height: 102px;
  overflow: hidden;
}
.divhalfcircle1 {
  height: 100%;
  width: calc(100% + 51px);
  top: 51px;
  right: 21.1px;
  bottom: -51px;
  left: -72.1px;
  border: 25px solid var(--color-gray-200);
  box-sizing: border-box;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}
.divhalfclip1 {
  position: absolute;
  height: 100%;
  width: calc(100% - 51px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 51px;
  overflow: hidden;
}
.divslider-img5,
.divslider-img6,
.divslider-img7 {
  position: absolute;
  top: 122px;
  left: 53.5px;
  border-radius: var(--br-32xl);
  width: 102px;
  height: 102px;
  overflow: hidden;
}
.divslider-img6,
.divslider-img7 {
  left: 298.5px;
}
.divslider-img7 {
  top: 244px;
  left: 176px;
}
.divslick-track1 {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: calc(50% - 234.5px);
  width: 470px;
}
.divslick-list1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 481px;
  height: 400px;
  overflow: hidden;
}
.divhappy-customer-right1 {
  position: absolute;
  top: 0;
  left: 669px;
  width: 453px;
  height: 419px;
}
.divrow1 {
  position: absolute;
  width: calc(100% - 115px);
  top: 110px;
  right: 80px;
  left: 35px;
  height: 572px;
}
.content-container18 {
  position: absolute;
  top: 294px;
  left: calc(50% - 567px);
  width: 1135px;
  height: 682px;
}
.rectangle-image-container1 {
  position: absolute;
  width: 100%;
  top: 2px;
  right: -1px;
  left: 1px;
  height: 1211px;
  overflow: hidden;
  font-size: var(--font-size-mini);
  color: var(--color-gray-200);
  font-family: var(--font-dm-sans);
}
.happy-customers1 {
  position: absolute;
  width: 100%;
  top: 6071px;
  right: 0;
  left: 0;
  background-color: var(--color-white);
  height: 1500px;
  text-align: left;
  color: var(--color-dodgerblue-100);
}
.start-for-free2 {
  position: absolute;
  top: 18px;
  left: 45px;
  line-height: 30px;
}
.button3 {
  position: absolute;
  top: 513px;
  left: calc(50% - 89px);
  border-radius: var(--br-81xl);
  background-color: var(--color-dodgerblue-100);
  width: 187.4px;
  height: 66px;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  color: var(--color-white);
}
.item-unlimited11,
.item-unlimited12,
.item-unlimited13,
.item-unlimited14 {
  position: absolute;
  top: 0;
  left: 24px;
  line-height: 30px;
  font-weight: 500;
}
.item-unlimited12,
.item-unlimited13,
.item-unlimited14 {
  top: 38px;
}
.item-unlimited13,
.item-unlimited14 {
  top: 76px;
}
.item-unlimited14 {
  top: 114px;
}
.item-2001,
.item-unlimited15,
.item-unlimited16,
.item-unlimited17 {
  position: absolute;
  top: 152px;
  left: 24px;
  line-height: 30px;
  font-weight: 500;
}
.item-unlimited15,
.item-unlimited16,
.item-unlimited17 {
  top: 190px;
}
.item-unlimited16,
.item-unlimited17 {
  top: 228px;
}
.item-unlimited17 {
  top: 266px;
}
.list9 {
  position: absolute;
  top: 159px;
  left: calc(50% - 143px);
  width: 318px;
  height: 296px;
  color: var(--color-darkslategray-100);
}
.compare-price-shapesvg-icon2 {
  position: relative;
  width: 86px;
  height: 86px;
  overflow: hidden;
  flex-shrink: 0;
}
.compare-price-shapesvg2 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.crownsvg-icon1 {
  position: relative;
  width: 22px;
  height: 19px;
  overflow: hidden;
  flex-shrink: 0;
}
.crownsvg1 {
  position: absolute;
  top: calc(50% - 8.2px);
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.icons2 {
  position: absolute;
  top: 71px;
  left: calc(50% + 68px);
  width: 86px;
  height: 86px;
}
.strong-191 {
  top: 0;
  left: 11px;
  font-size: var(--font-size-17xl);
  width: 45px;
  height: 44px;
}
.per-month2,
.per-month2-1,
.strong-191,
.superscript2 {
  position: absolute;
  line-height: 26px;
  display: flex;
  align-items: center;
}
.per-month2 {
  top: 20px;
  left: 63.3px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  width: 88.1px;
  height: 24px;
}
.per-month2-1 {
  top: 20px;
  left: 55.3px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  width: 88.1px;
  height: 24px;
}
.superscript2 {
  top: -6px;
  left: 0;
  font-weight: 600;
  width: 11.5px;
  height: 22px;
}
.heading-220,
.heading-512 {
  position: absolute;
  left: calc(50% - 149px);
  font-size: var(--font-size-lg);
}
.heading-220 {
  top: 100px;
  width: 200px;
  height: 44px;
  font-family: var(--font-montserrat-alternates);
}
.heading-512 {
  top: 42px;
  line-height: 26px;
  display: flex;
  align-items: center;
  width: 164px;
  height: 30px;
}
.jobseeker-price-card1 {
  position: absolute;
  top: 0;
  left: calc(50% - 600px);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 380px;
  height: 611px;
}
.button4 {
  position: absolute;
  top: 513px;
  left: calc(50% - 94.5px);
  border-radius: var(--br-81xl);
  background-color: var(--color-dodgerblue-100);
  width: 187.4px;
  height: 66px;
  overflow: hidden;
  text-align: center;
  color: var(--color-white);
}
.item-email1,
.item-unlimited18 {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.item-unlimited18 {
  top: 43px;
}
.item-great1,
.item-unlimited19,
.item-unlimited20,
.item-unlimited21 {
  position: absolute;
  top: 81px;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.item-great1,
.item-unlimited20,
.item-unlimited21 {
  top: 119px;
}
.item-great1,
.item-unlimited21 {
  top: 157px;
}
.item-unlimited21 {
  top: 195px;
}
.list10 {
  position: absolute;
  top: 159px;
  left: calc(50% - 143px);
  width: 318px;
  height: 296px;
  color: var(--color-darkslategray-100);
}
.compare-price-shapesvg3 {
  position: absolute;
  top: calc(50% - 43px);
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.crown-2svg-icon1 {
  position: relative;
  width: 22px;
  height: 22px;
  overflow: hidden;
  flex-shrink: 0;
}
.crown-2svg1 {
  position: absolute;
  top: calc(50% - 9.7px);
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.icons3 {
  position: absolute;
  top: 71px;
  left: calc(50% + 62.5px);
  width: 86px;
  height: 86px;
}
.heading-221,
.heading-513 {
  position: absolute;
  left: calc(50% - 154.5px);
  font-size: var(--font-size-lg);
}
.heading-221 {
  top: 100px;
  width: 176px;
  height: 44px;
  font-family: var(--font-montserrat-alternates);
}
.heading-513 {
  top: 42px;
  line-height: 26px;
  display: flex;
  align-items: center;
  width: 141px;
  height: 30px;
}
.recruiter-price-card1 {
  position: absolute;
  top: 0;
  left: calc(50% - 201px);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 391px;
  height: 611px;
}
.university-price-card1 {
  position: absolute;
  top: 0;
  left: calc(50% + 205px);
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 391px;
  height: 611px;
}
.price-card-container1 {
  position: absolute;
  top: 421px;
  left: calc(50% - 438px);
  width: 936px;
  height: 611px;
  z-index: 1;
}
.ball1svg-icon3 {
  position: absolute;
  top: 70px;
  left: 18px;
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.pricing-table1 {
  position: absolute;
  top: 29px;
  left: 169px;
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 178.7px;
  height: 24px;
}
.top-heading-container1 {
  position: relative;
  width: 456px;
  height: 65px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
  font-size: var(--font-size-lg);
  color: var(--color-dodgerblue-100);
  font-family: var(--font-dm-sans);
}
.giving-you-good1 {
  position: absolute;
  top: 14px;
  left: 17px;
  line-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 475.8px;
  height: 49px;
}
.heading1-container1 {
  position: relative;
  width: 493px;
  height: 68px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.with1 {
  position: absolute;
  top: 20px;
  left: calc(50% - 182px);
  line-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 107.3px;
  height: 49px;
}
.heading2-container1 {
  position: relative;
  width: 476px;
  height: 90px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}
.best-service1 {
  position: absolute;
  top: 0;
  left: 10px;
  line-height: 62px;
}
.yellow-badge3 {
  position: absolute;
  margin: 0 !important;
  top: 181px;
  left: calc(50% - 58.5px);
  border-radius: 0 var(--br-xl) 0 0;
  background-color: var(--color-goldenrod);
  width: 271px;
  height: 62px;
  z-index: 3;
}
.heading-container7 {
  position: absolute;
  top: 7px;
  left: 211px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-2xs);
  gap: 17px;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.button-monthly1,
.text-container18 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-monthly1 {
  position: relative;
  line-height: 30px;
  width: 56.4px;
  height: 20px;
  flex-shrink: 0;
}
.text-container18 {
  position: absolute;
  top: -3px;
  left: 121px;
  overflow: hidden;
  flex-direction: row;
  padding: 5px var(--padding-sm);
}
.input1,
.pseudo9 {
  position: absolute;
  top: 5px;
  left: 6px;
  border-radius: var(--br-6xs);
  background-color: var(--color-dodgerblue-200);
  width: 14px;
  height: 14px;
}
.input1 {
  top: 3px;
  left: 239px;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 70px;
  height: 24px;
}
.off1 {
  color: var(--color-darkturquoise);
}
.yearly-20-off-container3 {
  line-break: anywhere;
  width: 100%;
}
.yearly-20-off-container2 {
  position: relative;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 137px;
  flex-shrink: 0;
}
.button5 {
  position: absolute;
  top: 0;
  left: 326px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.yearly-payment-tab1 {
  position: absolute;
  top: 325px;
  left: 168px;
  width: 572px;
  height: 30px;
}
.img-icon15 {
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.no-cards-required1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
}
.item21,
.item22,
.item23 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.item22,
.item23 {
  left: 185.4px;
}
.item23 {
  left: 356.3px;
}
.list11 {
  position: relative;
  width: 556.1px;
  height: 30px;
}
.card-offers1 {
  position: absolute;
  top: 1031px;
  left: calc(50% - 354.05px);
  border-radius: var(--br-16xl);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-57xl);
  text-align: left;
}
.ball1svg-parent {
  position: absolute;
  top: 29px;
  left: calc(50% - 438px);
  width: 936.1px;
  height: 1071px;
  overflow: hidden;
  text-align: center;
  color: var(--color-darkslategray-100);
}
.pricing3 {
  position: absolute;
  width: 100%;
  top: 4916px;
  right: 0;
  left: 0;
  height: 1155px;
  text-align: left;
  font-size: var(--font-size-mini);
  font-family: var(--font-dm-sans);
}
.product3 {
  position: relative;
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
  width: 196.3px;
  height: 24px;
  flex-shrink: 0;
}
.heading-222,
.product3,
.title-container1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-222 {
  position: relative;
  font-size: var(--font-size-21xl);
  line-height: 62px;
  font-family: var(--font-montserrat-alternates);
  color: var(--color-gray-200);
  width: 437px;
  height: 49px;
  flex-shrink: 0;
}
.title-container1 {
  flex-direction: column;
  padding: 0 44px;
  gap: 44px;
  z-index: 0;
}
.ball2svg-icon6 {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
}
.integrate-emails-container-icon1 {
  position: relative;
  width: 379.9px;
  height: 250px;
  object-fit: cover;
}
.b6,
.image-container12 {
  position: absolute;
  display: flex;
  align-items: center;
}
.image-container12 {
  top: 0;
  left: calc(50% - 605.5px);
  width: 498px;
  height: 396px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 72px 54px;
  box-sizing: border-box;
}
.b6 {
  top: 12px;
  left: 19px;
  line-height: 30px;
  width: 6.1px;
  height: 19px;
}
.divpowerfull-template-number6 {
  position: absolute;
  top: 51px;
  left: 532px;
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-100);
  border: 10px solid var(--color-white);
  box-sizing: border-box;
  width: 60px;
  height: 60px;
}
.heading-46 {
  position: absolute;
  top: 52px;
  left: calc(50% - 205px);
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.integrate-outlook-g1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 354px;
  flex-shrink: 0;
}
.sub-content1 {
  position: absolute;
  top: calc(50% - 90px);
  left: 36.5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-3xs) 0 0;
  color: var(--color-darkslategray-100);
}
.item24,
.item25 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-mini);
}
.item24 {
  display: flex;
  justify-content: flex-start;
  /* padding: 0 var(--padding-104xl) 0 0; */
}
.item25 {
  width: 458px;
  /* padding: 0 var(--padding-186xl) 0 0; */
  box-sizing: border-box;
}
.item25,
.item26,
.list12 {
  display: flex;
  justify-content: flex-start;
}
.item26 {
  flex-direction: row;
  align-items: center;
  /* padding: 0 var(--padding-237xl) 0 0; */
  gap: var(--gap-mini);
}
.list12 {
  position: absolute;
  top: 240px;
  left: calc(50% - 237.5px);
  width: 457px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8xs);
}
.integrate-emails-content-conta1 {
  position: absolute;
  top: 12px;
  left: calc(50% + 76.5px);
  width: 484px;
  height: 450px;
  font-family: var(--font-dm-sans);
}
.integrate-emails-row1 {
  position: relative;
  width: 1211px;
  height: 455px;
}
.heading-47 {
  position: relative;
  line-height: 35.04px;
}
.heading-614 {
  position: absolute;
  top: 10px;
  left: 70px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-30xl) 0.779998779296875px 0;
  font-size: var(--font-size-5xl);
  font-family: var(--font-montserrat-alternates);
}
.maintain-candidates-resumes1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 360px;
  flex-shrink: 0;
}
.subcontent1 {
  position: absolute;
  top: 82.8px;
  left: 70px;
  padding: 0 var(--padding-base) 0 0;
  color: var(--color-darkslategray-100);
}
.monitor-iconsvg4,
.subcontent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.all-candidates-profiles1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 108px;
}
.item27,
.item28 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.item27 {
  padding: 0.2899999916553497var (--padding-10xs) 3px 0 0;
}
.item28 {
  padding: 0.28999999165534973px var(--padding-8xl) 0 0;
}
.centralized-job-vacancies1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 88px;
}
.item29,
.ordered-list2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.item29 {
  flex-direction: column;
  padding: 0.28999999165534973px var(--padding-28xl) 0 0;
  gap: var(--gap-3xs);
}
.ordered-list2 {
  position: absolute;
  top: 178.8px;
  left: 70px;
  flex-direction: row;
  gap: var(--gap-xl);
}
.content-container20 {
  position: absolute;
  top: 0;
  left: calc(50% - 605.5px);
  width: 498px;
  height: 396px;
}
.b7 {
  position: absolute;
  top: 12px;
  left: 17.6px;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 9.1px;
  height: 19px;
}
.divpowerfull-template-number7 {
  position: absolute;
  top: 0px;
  left: 533px;
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-100);
  border: 10px solid var(--color-white);
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  font-family: var(--font-montserrat-alternates);
}
.centralised-database-image-icon1 {
  position: relative;
  width: 416px;
  height: 329px;
  object-fit: cover;
}
.image-container13 {
  position: absolute;
  top: 0;
  left: calc(50% + 76.5px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-30xl);
}
.centralised-database1 {
  position: relative;
  width: 1211px;
  height: 455px;
  font-family: var(--font-dm-sans);
}
.sends-assesment-image-icon1 {
  position: relative;
  width: 423px;
  height: 294px;
  object-fit: cover;
}
.b8,
.image-container14 {
  position: absolute;
  display: flex;
  align-items: center;
}
.image-container14 {
  top: 0;
  left: calc(50% - 605.5px);
  width: 498px;
  height: 396px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 53px 37px;
  box-sizing: border-box;
}
.b8 {
  top: 12px;
  left: 17.5px;
  line-height: 30px;
  width: 9.1px;
  height: 19px;
}
.divpowerfull-template-number8 {
  position: absolute;
  top: 63px;
  left: 532px;
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-100);
  border: 10px solid var(--color-white);
  box-sizing: border-box;
  width: 60px;
  height: 60px;
}
.heading-48 {
  position: absolute;
  top: 75px;
  left: 57px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.once-the-profile1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 442px;
  height: 104px;
  flex-shrink: 0;
}
.p5 {
  position: absolute;
  top: 134px;
  left: 57px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-4xl) 0 0;
  color: var(--color-darkslategray-100);
}
.item31,
.list13,
.p5 {
  display: flex;
  justify-content: flex-start;
}
.item31 {
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-131xl) 0 0;
  gap: var(--gap-mini);
}
.list13 {
  position: absolute;
  top: 238px;
  left: 56.9px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8xs);
}
.ball2svg1 {
  position: absolute;
  top: 328px;
  left: calc(50% + 172px);
  width: 80px;
}
.content-container21 {
  position: absolute;
  top: 0;
  left: calc(50% + 46.5px);
  width: 484px;
  height: 450px;
  font-family: var(--font-dm-sans);
}
.heading-49 {
  position: absolute;
  top: 75px;
  left: 66px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.profiles-are-shortlisted2 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 358px;
  flex-shrink: 0;
}
.item34,
.p6 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.p6 {
  position: absolute;
  top: 134px;
  left: 66px;
  align-items: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
  color: var(--color-darkslategray-100);
}
.item34 {
  align-items: center;
  /* padding: 0 var(--padding-186xl) 0 0; */
  gap: var(--gap-mini);
}
.b9,
.list14 {
  position: absolute;
}
.list14 {
  top: 272px;
  left: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.b9 {
  top: 7px;
  left: 17px;
  line-height: 30px;
}
.divpowerfull-template-number9 {
  position: absolute;
  top: 63px;
  /* left: calc(50% - 81.5px);
   */
   left: 532px;

  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-100);
  border: 10px solid var(--color-white);
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  font-family: var(--font-montserrat-alternates);
}
.shortlist-profiles-image-icon1 {
  position: relative;
  width: 416px;
  height: 332px;
  object-fit: cover;
}
.image-container15 {
  position: absolute;
  top: 0;
  left: calc(50% + 60.5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-7xl) 48px;
}
.schedule-interview-image-icon1 {
  position: relative;
  width: 433.5px;
  height: 297.1px;
  object-fit: cover;
}
.b10,
.image-container16 {
  position: absolute;
  display: flex;
  align-items: center;
}
.image-container16 {
  top: 0;
  left: calc(50% - 587.5px);
  width: 498px;
  height: 396px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 68px var(--padding-7xl);
  box-sizing: border-box;
}
.b10 {
  top: 12.5px;
  left: 18px;
  line-height: 30px;
  width: 9.1px;
  height: 19px;
}
.divpowerfull-template-number10 {
  position: absolute;
  top: 63px;
  /* left: calc(50% - 85.5px); */
  left: 532px;

  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-100);
  border: 10px solid var(--color-white);
  box-sizing: border-box;
  width: 60px;
  height: 60px;
}
.heading-410 {
  position: absolute;
  top: 75px;
  left: 56px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.interviewrs-profiles-can1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 401px;
  flex-shrink: 0;
}
.heading-615 {
  position: absolute;
  top: 133px;
  left: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-30xl) 0.779998779296875px 0;
}
.auto-interview-slot1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 111px;
}
.item36 {
  width: 136px;
  height: 61px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.2899999916553497var (--padding-10xs) 3px 0 0;
  box-sizing: border-box;
  gap: var(--gap-3xs);
}
.auto-emails-for1,
.reminders-before-interviews1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.auto-emails-for1 {
  width: 135px;
  height: 76px;
  flex-shrink: 0;
}
.reminders-before-interviews1 {
  width: 113px;
}
.ordered-list3 {
  position: absolute;
  top: 294px;
  left: 56px;
  width: 482px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.content-container23 {
  position: absolute;
  top: 0;
  left: calc(50% + 42.5px);
  width: 484px;
  height: 450px;
  font-family: var(--font-dm-sans);
}
.heading-411 {
  position: absolute;
  top: 45px;
  left: 21px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.after-the-feedback1 {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 335px;
  flex-shrink: 0;
}
.list15,
.p7 {
  position: absolute;
  left: 21px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.p7 {
  top: 113px;
  flex-direction: row;
  padding: 0 var(--padding-4xl) 0 0;
  color: var(--color-darkslategray-100);
}
.list15 {
  top: 205px;
  width: 437px;
  flex-direction: column;
  gap: var(--gap-8xs);
}
.content-container24 {
  position: absolute;
  top: 0;
  left: calc(50% - 585.5px);
  width: 498px;
  height: 396px;
  overflow: hidden;
}
.b11 {
  position: absolute;
  top: 12px;
  left: 17px;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 9.1px;
  height: 19px;
}
.divpowerfull-template-number11 {
  position: absolute;
  top: 29px;
  /* left: calc(50% - 85.5px); */
  left: 532px;

  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-100);
  border: 10px solid var(--color-white);
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  font-family: var(--font-montserrat-alternates);
}
.send-results-image-icon1 {
  position: relative;
  width: 486px;
  height: 355px;
  object-fit: cover;
}
.image-container17,
.product-recruiters-content1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image-container17 {
  position: absolute;
  top: -3px;
  left: calc(50% + 74.5px);
  justify-content: flex-start;
}
.product-recruiters-content1 {
  width: 1156px;
  height: 2735px;
  justify-content: center;
  padding: 0 var(--padding-3xs);
  box-sizing: border-box;
  gap: 1px;
  z-index: 2;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.divdividing-line1 {
  margin: 0 !important;
    position: absolute;
    top: calc(50% - 1267px);
    left: calc(50% + 0.5px);
    background-color: var(--color-gray-500);
    width: 6px;
    height: 2572px;
    z-index: 3;
}
.content-container19 {
  position: absolute;
  top: 17px;
  left: calc(50% - 583px);
  width: 1169px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: var(--padding-30xl) 0;
  box-sizing: border-box;
  gap: var(--gap-base);
}
.product-recruiter1 {
  position: absolute;
  width: 100%;
  top: 1922px;
  right: 0;
  left: 0;
  background-color: var(--color-gray-600);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  height: 2994px;
  overflow: hidden;
  font-size: var(--font-size-13xl);
  color: var(--color-dodgerblue-100);
  font-family: var(--font-dm-sans);
}
.heading-514,
.prepare-for-live1 {
  position: relative;
  line-height: 26.28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 169px;
  height: 22px;
  flex-shrink: 0;
}
.prepare-for-live1 {
  font-size: var(--font-size-mini);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 318.4px;
  height: 80px;
}
.text-container19 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-11xl) var(--padding-base);
  gap: var(--gap-41xl);
}
.interview-prep-image-icon1 {
  position: relative;
  width: 91px;
  height: 108px;
  object-fit: cover;
}
.image-container18 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-smi);
}
.interview-preparation1 {
  border-radius: var(--br-11xl);
  background-color: var(--color-ghostwhite);
  width: 384px;
  height: 388px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-mini);
  box-sizing: border-box;
}
.get-career-advise1,
.heading-515 {
  position: absolute;
  top: 35.8px;
  left: 101px;
  line-height: 26.28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128.6px;
  height: 22px;
}
.get-career-advise1 {
  top: 121.8px;
  left: 6px;
  font-size: var(--font-size-mini);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 318.4px;
  height: 80px;
}
.text-container20 {
  position: absolute;
  top: 21px;
  left: 20px;
  width: 330.4px;
  height: 202px;
}
.career-advise-image-icon1 {
  position: absolute;
  top: 19.8px;
  left: 0;
  width: 108px;
  height: 128px;
  object-fit: cover;
}
.image-container19 {
  position: absolute;
  top: 234px;
  left: 131.2px;
  width: 108px;
  height: 128px;
  overflow: hidden;
}
.career-advise1 {
  position: relative;
  border-radius: var(--br-11xl);
  /* background-color: var(--color-white); */
  /* background-color: #CAF5FA; */

  width: 370.4px;
  height: 383px;
  overflow: hidden;
  flex-shrink: 0;
}
.heading-516,
.relax-while-our1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-516 {
  top: 48px;
  left: 104px;
  line-height: 26.28px;
  width: 125px;
  height: 22px;
}
.relax-while-our1 {
  top: 120px;
  left: 8px;
  font-size: var(--font-size-mini);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 318.4px;
}
.text-conatiner2 {
  position: relative;
  width: 333px;
  height: 220px;
}
.autojob-apply-image-icon1 {
  position: relative;
  width: 116px;
  height: 112px;
  object-fit: cover;
}
.auto-apply3,
.image-container20 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
}
.auto-apply3 {
  border-radius: var(--br-11xl);
  background-color: var(--color-ghostwhite);
  width: 373px;
  height: 383px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-lg);
  box-sizing: border-box;
  gap: var(--gap-lgi);
}
.job-seeker-product-second-row1 {
  position: absolute;
  top: 440.5px;
  left: calc(50% - 608.5px);
  width: 1217.4px;
  height: 387.8px;
  flex-direction: row;
  padding: 0 var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-11xl);
}
.heading-517,
.job-seeker-product-second-row1,
.text-container21 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-517 {
  position: relative;
  line-height: 26.28px;
  width: 214px;
  height: 22px;
  flex-shrink: 0;
}
.text-container21 {
  height: 224px;
  flex-direction: column;
  padding: var(--padding-9xl) var(--padding-9xs) var(--padding-14xl);
  box-sizing: border-box;
  gap: var(--gap-42xl);
}
.resume-builder-image-icon1 {
  position: relative;
  width: 125px;
  height: 144px;
  object-fit: cover;
}
.build-and-enhance-resume1,
.image-container21 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-2xl);
}
.build-and-enhance-resume1 {
  border-radius: var(--br-11xl);
  /* background-color: var(--color-white);
  */
  /* background-color: #CAF5FA; */

  width: 379px;
  height: 388px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-7xs) var(--padding-lgi);
  box-sizing: border-box;
}
.craft-compelling-cover1,
.heading-518 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-518 {
  line-height: 26.28px;
  width: 128px;
  height: 78px;
  flex-shrink: 0;
}
.craft-compelling-cover1 {
  font-size: var(--font-size-mini);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 318.4px;
}
.text-container22 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
  gap: var(--gap-19xl);
}
.cover-letter-image-icon1 {
  position: relative;
  width: 111px;
  height: 112.7px;
  object-fit: cover;
}
.image-container22 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.build-cover-letters1 {
  border-radius: var(--br-11xl);
  background-color: var(--color-ghostwhite);
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-6xs) 0;
  gap: var(--gap-53xl);
}
.build-cover-letters1,
.heading-519,
.text-conatiner3 {
  display: flex;
  align-items: center;
}
.heading-519 {
  position: relative;
  line-height: 26.28px;
  justify-content: center;
  width: 194.5px;
  height: 22px;
  flex-shrink: 0;
}
.text-conatiner3 {
  height: 242px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-9xl) var(--padding-5xs) var(--padding-14xl);
  box-sizing: border-box;
  gap: var(--gap-41xl);
}
.job-search-image-icon1 {
  position: relative;
  width: 115px;
  height: 114px;
  object-fit: cover;
}
.image-container23 {
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-xs);
}
.image-container23,
.job-search1,
.job-seeker-product-first-row1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.job-search1 {
  border-radius: var(--br-11xl);
  overflow: hidden;
  flex-direction: column;
  padding: 0 var(--padding-xs);
  /* background-color: #CAF5FA; */

}
.job-seeker-product-first-row1 {
  position: absolute;
  top: 0;
  left: calc(50% - 596.5px);
  width: 1193.8px;
  height: 387.8px;
  flex-direction: row;
  padding: 0 var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-11xl);
}
.job-seeker-product-second-row-group {
  position: absolute;
  top: 274px;
  left: calc(50% - 588px);
  width: 1175px;
  height: 828px;
}
.heading-223,
.title-conatiner3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-223 {
  position: relative;
  font-size: var(--font-size-21xl);
  line-height: 62px;
  font-family: var(--font-montserrat-alternates);
  color: var(--color-gray-200);
  width: 415px;
}
.title-conatiner3 {
  position: absolute;
  top: 106px;
  left: calc(50% - 216px);
  width: 432px;
  flex-direction: column;
  padding: 0 34px;
  box-sizing: border-box;
  gap: 27px;
  font-size: var(--font-size-13xl);
  color: var(--color-dodgerblue-100);
  font-family: var(--font-dm-sans);
}
.ball3svg-icon6 {
  position: relative;
  width: 122px;
  height: 122px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.ball-container7 {
  position: absolute;
  top: 92px;
  right: 232px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xs) 0;
}
.product-job-seekers1 {
  position: absolute;
  width: 100%;
  top: 734px;
  right: 0;
  left: 0;
  height: 1188px;
  font-size: var(--font-size-lg);
}
.opening-page-gif-icon1 {
  position: relative;
  width: 508px;
  height: 524px;
  object-fit: cover;
}
.image-container-21 {
  position: absolute;
  top: 83px;
  left: calc(50% + 158px);
  width: 508px;
  height: 524px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.democratizing-recruitment1,
.text-container23 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.democratizing-recruitment1 {
  position: relative;
  line-height: 72px;
  width: 582px;
  flex-shrink: 0;
}
.text-container23 {
  position: absolute;
  top: 0;
  left: calc(50% - 311.5px);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-xs) 0;
}
.text-gif-icon {
  position: relative;
  width: 676px;
  height: 315px;
  object-fit: cover;
}
.image-container-1 {
  position: absolute;
  top: 80px;
  left: calc(50% - 390px);
  width: 730px;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.text-container-group {
  position: absolute;
  top: 83px;
  left: calc(50% - 721px);
  width: 711px;
  height: 545px;
}
.opening-page1,
.website-main1 {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
}
.opening-page1 {
  top:-20.5px ;
  border-radius: 0 0 var(--br-101xl) var(--br-101xl);
  background: linear-gradient(305deg, #ffffff 0%, rgba(190, 225, 236, 0.63) 56.47%, rgba(5, 240, 253, 0.00) 121.12%);


  height: 734px;
  font-size: var(--font-size-41xl);
}
.website-main1 {
  top: 137px;
  background-color: var(--color-white);
  height: 12393px;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.all-rights-reserved3,
.item-link12,
.item-link13,
.item-link14 {
  position: absolute;
  top: 5px;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 20px;
}
.all-rights-reserved3 {
  left: calc(50% - 462px);
  width: 219.6px;
}
.item-link12,
.item-link13,
.item-link14 {
  left: 5px;
  width: 153.1px;
}
.item-link13,
.item-link14 {
  left: 188.1px;
  width: 57.4px;
}
.item-link14 {
  left: 275.5px;
  width: 99.2px;
}
.all-rights-reserved2,
.list16 {
  position: absolute;
  top: 5px;
  left: calc(50% - 0px);
  width: 379.8px;
  height: 30px;
}
.all-rights-reserved2 {
  top: 547px;
  left: calc(50% - 485px);
  width: 954px;
}
.heading-616,
.item-link15 {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading-616 {
  font-size: 26.73px;
  line-height: 8.51px;
  text-transform: uppercase;
  font-weight: 600;
  /* font-family: var(--font-montserrat-alternates); */
  color: var(--color-black);
  width: 160.4px;
  height: 26.7px;
}
.item-link15 {
  line-height: 45.07px;
  width: 225.3px;
  height: 38.2px;
}
.products3 {
  position: absolute;
  top: 0;
  left: calc(50% - 281.6px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15.27px;
}
.heading-617,
.item-link19 {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading-617 {
  font-size: 26.73px;
  line-height: 8.51px;
  text-transform: uppercase;
  font-weight: 600;
  /* font-family: var(--font-montserrat-alternates); */
  color: var(--color-black);
  width: 190.9px;
  height: 26.7px;
}
.item-link19 {
  line-height: 45.07px;
  width: 252px;
  height: 38.2px;
}
.heading-6-products-group {
  position: absolute;
  top: 0;
  left: calc(50% + 29.6px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15.27px;
}
.products-group {
  position: absolute;
  top: 90px;
  left: calc(50% - 47px);
  width: 563.2px;
  height: 294px;
  overflow: hidden;
  font-size: 22.91px;
}
.facebooksvg-icon1,
.follow1 {
  position: relative;
  flex-shrink: 0;
}
.follow1 {
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 55.6px;
  height: 19px;
}
.facebooksvg-icon1 {
  width: 17px;
  height: 17px;
  overflow: hidden;
}
.linkedinsvg-icon1{
  width: 19px;
  height: 19px;
  overflow: hidden;

}
.item-link-facebooksvg1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0.7;
}
.twittersvg-icon {
  position: relative;
  width: 13px;
  height: 13px;
  overflow: hidden;
  flex-shrink: 0;
}
.item-link-twittersvg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0.7;
}
.instasvg-icon1 {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.follow-us1,
.list17 {
  display: flex;
  flex-direction: row;
}
.list17 {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8.279999732971191px 0 5.720000267028809px;
  gap: var(--gap-9xl);
}
.follow-us1 {
  position: absolute;
  top: 451px;
  left: calc(50% + 95px);
  width:350px;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xl);
  color: var(--color-black);
  font-family: var(--font-montserrat-alternates);
}
.recruiter-robo-logo-3-2-icon1 {
  position: relative;
  width: 86px;
  height: 96px;
  object-fit: cover;
}
.logo-container1 {
  position: absolute;
  top: 46px;
  left: 47px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.recruiter-robo-is {
  position: absolute;
  top: 3px;
  left: 37.5px;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 289px;
}
.website-info-content1 {
  position: absolute;
  top: 167px;
  left: 0;
  width: 364px;
  height: 246px;
}
.logo-container-group {
  position: absolute;
  top: 30px;
  left: calc(50% - 522px);
  width: 364px;
  height: 413px;
  font-size: 22.91px;
  color: var(--color-gray-800);
}
.content-container25 {
  position: absolute;
  width: 100%;
  right: -2px;
  bottom: 1px;
  left: 2px;
  border-top: 1px solid var(--color-gainsboro-300);
  box-sizing: border-box;
  height: 674px;
}
.green-ball-image-container3 {
  position: absolute;
  top: 419px;
  left: calc(50% - 107px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
}
.website-footer1 {
  position: absolute;
  width: 100%;
  top: 12530px;
  right: 0;
  left: 0;
  background-color: var(--color-gray-900);
  height: 675px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-gray-700);
  font-family: var(--font-dm-sans);
}
.landing-page-laptop1220 {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 13205px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-midnightblue-100);
  font-family: var(--font-roboto);
  zoom: 85%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.svg-container {
  animation: fadeInOut 2s linear;
  zoom: 82%;
}
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(500px); /* Adjust the distance as needed */
  }
}

 

.yball {
  rotate: 0deg;
  float: right;
  margin: 200px 0 0 50px;
  width: 400px; /* Adjust the size as needed */
  height: 100px;
  animation: moveUpDown 6s linear infinite;
}