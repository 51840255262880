.job-seekers {
  /* position: absolute; */
  margin: 0 !important;
  top: 0;
  left: calc(50% - 64px);
  line-height: 24px;
  z-index: 0;
}
.toggle-btn{
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggle-button{
  margin: 0 20px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}
.job-seeker {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  position: relative;
  cursor: pointer;
}
.company1 {
  position: relative;
  line-height: 24px;
}
.company,
.right-fields {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-between;
  /* padding: 0 var(--padding-11xs); */
}
.right-fields {
  position: absolute;
  top: 25px;
  right: 0px;
  align-items: center;
  /* justify-content: space-between; */
  padding: var(--padding-3xs) var(--padding-mid);
  /* gap: var(--gap-3xs); */
  gap: 12px;
  
}
.product {
  /* position: absolute;
  top: 0;
  left: 0; */
  /* width: 100%; */
  line-height: 24px;
}
.landingpage{
  color: grey;
  font-size: x-large;
}
.landingpage:hover{
  
  font-weight: 800;
}
.product-tag {
  /* position: absolute;
  top: 41px;
  left: 33px;
  width: 100px; */
  /* width: 30%; */
  height: 26px;
  cursor: pointer;
  text-align: center;
}
.pricing {
  /* position: absolute;
  top: 0;
  left: 17.5px; */
  line-height: 24px;
  /* width: 100%; */
}

.about-us-tag,
.pricing-tag {
  /* position: absolute;
  top: 41px;
  left: 141px;
  width: 100px; */
  /* width: 30%; */
  height: 26px;
  cursor: pointer;
}
.about-us-tag {
  /* left: 283px; */
  /* width: 30%; */
}
.left-fields {
  position: absolute;
  /* top: 44px; */
  left: calc(50% - 355px);
  width: 35%;
  height: 100%;
  text-align: center;
  display: flex;
  gap: 5%;
  align-items: center;
}
.website-name {
  position: absolute;
  top: 10.5px;
  left: 11px;
  line-height: 135.5%;
  font-weight: 600;
  color: #000;
}
.website-name-frame-op1 {
  position: absolute;
  top: 34px;
  left: 81px;
  width: 203px;
  height: 53px;
  overflow: hidden;
}
.website-name-frame-op2 {
    position: absolute;
    top: 55px;
    left: 80.5px;
    width: 203px;
    height: 53px;
    overflow: hidden;
    font-size: 25.5px;
  
}
.website-name-frame-op3 {
  position: absolute;
    top: 73px;
    left: 10px;
    width: 203px;
    height: 53px;
    overflow: hidden;
    font-size: 23px;
}
.recruiter-robo-logo-3-1-icon-op1 {
  position: absolute;
  top: 14px;
  left: 17px;
  /* width: 70px;
  height: 89px; */
  width: 64px;
  height: 78px;
  object-fit: cover;
}
.recruiter-robo-logo-3-1-icon-op2 {
  position: absolute;
    top: 19px;
    left: 25px;
    width: 65.5px;
    height: 79.5px;
    object-fit: cover;
}
.recruiter-robo-logo-3-1-icon-op3 {
  position: absolute;
  top: 17px;
  left: 33px;
  width: 60px;
  height: 64px;
  object-fit: cover;
}

.logo-frame {
  position: absolute;
  top: -1px;
  left: 0;
  width: 127px;
  height: 111px;
  overflow: hidden;
}
.website-name-frame-parent {
  position: absolute;
  top: 0;
  left: 12px;
  width: 330px;
  height: 111px;
  font-size: var(--font-size-5xl);
  color: var(--color-gray-100);
  font-family: var(--font-poppins);
}
.header,
.website-header {
  position: absolute;
  top: 0;
  left: 0;
  height: 112px;
}
.header {
  width: calc(100% + 2px);
  right: -2px;
  background-color: var(--color-white);
  /* width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: space-between; */
}
.website-header {
  width: 100%;
  right: 0;
  overflow: hidden;
}
.job-seekers1,
.recruiters {
  position: absolute;
  top: 41px;
  left: 148px;
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 176.7px;
  height: 23.1px;
}
.recruiters {
  left: 765.7px;
}
.subheading-container {
  position: relative;
  width: 1090.4px;
  height: 105.1px;
  overflow: hidden;
  flex-shrink: 0;
}
.heading-6,
.our-all-the {
  position: absolute;
  top: 110px;
  left: 15px;
  line-height: 30.83px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 391.4px;
  height: 52.8px;
}
.heading-6 {
  top: 36px;
  font-size: 25.43px;
  line-height: 34.33px;
  font-family: var(--font-plus-jakarta-sans);
  width: 391px;
  height: 32px;
}
.text-container {
  position: absolute;
  margin: 0 !important;
  top: -20px;
  left: 0;
  width: 422px;
  height: 180px;
  z-index: 0;
}
.st-advantage-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}
.everything-you-require,
.heading-61 {
  position: absolute;
  top: 75px;
  left: 11px;
  line-height: 30.83px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 391.4px;
  height: 52.8px;
}
.heading-61 {
  top: 4px;
  font-size: 25.43px;
  line-height: 34.33px;
  font-family: var(--font-plus-jakarta-sans);
  width: 391px;
  height: 48px;
}
.text-container1 {
  position: relative;
  width: 433px;
  height: 160px;
}
.nd-advantage-container {
  position: absolute;
  top: 183px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.job-seeker-column {
  position: absolute;
  top: 0;
  left: 0;
  width: 422px;
  height: 351px;
  overflow: hidden;
}
.heading-62,
.no-waiting-for {
  position: absolute;
  top: 1px;
  left: 0;
  line-height: 34.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 387px;
  height: 32px;
}
.no-waiting-for {
  top: 53px;
  font-size: var(--font-size-lg-5);
  line-height: 30.83px;
  font-family: var(--font-rubik);
  width: 386.5px;
  height: 52.8px;
}
.text-container2 {
  position: absolute;
  top: 21px;
  left: 11px;
  width: 409px;
  height: 139px;
}
.st-advantage-container1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 420px;
  height: 161px;
}
.heading-63,
.in-house-candidates {
  position: absolute;
  top: 13px;
  left: 0;
  line-height: 34.33px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 465px;
  height: 44px;
}
.in-house-candidates {
  top: 73px;
  font-size: var(--font-size-lg-5);
  line-height: 30.83px;
  font-family: var(--font-rubik);
  height: 70px;
}
.text-container3 {
  position: absolute;
  top: 15.2px;
  left: 0;
  width: 465px;
  height: 129px;
}
.nd-advantage-container1,
.recruiter-column {
  position: absolute;
  top: 160.8px;
  left: 0;
  width: 465px;
  height: 161px;
}
.recruiter-column {
  top: -1px;
  left: 618px;
  height: 321.8px;
  overflow: hidden;
  font-size: 25.43px;
  font-family: var(--font-plus-jakarta-sans);
}
.content-rows-container {
  position: relative;
  width: 1083px;
  height: 351px;
  font-size: var(--font-size-lg-5);
  color: var(--color-black);
  font-family: var(--font-rubik);
}
.subheading-container-parent {
  position: absolute;
  width: calc(100% - 547.6px);
  top: 163px;
  right: 246.6px;
  left: 301px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-4xl);
}
.heading-2,
.yellow-text {
  position: absolute;
  height: 49px;
}
.yellow-text {
  top: 52px;
  left: calc(50% - 321px);
  border-radius: 0 var(--br-xl) 0 0;
  background-color: var(--color-goldenrod);
  width: 270.8px;
}
.heading-2 {
  top: 55px;
  left: 264px;
  line-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 571px;
}
.heading-container {
  position: absolute;
  top: 0;
  left: calc(50% - 533px);
  width: 1170px;
  height: 100.1px;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.content-container {
  position: absolute;
  width: calc(100% - 272px);
  top: 56px;
  right: 179px;
  left: 93px;
  height: 703px;
  overflow: hidden;
}
.yellow-ball-image-container-icon {
  position: absolute;
  top: 78px;
  right: 181px;
  width: 246px;
  height: 246px;
  overflow: hidden;
  object-fit: cover;
}
.whyus {
  position: absolute;
  top: 11916px;
  left: calc(50% - 961px);
  /* background-color: var(--color-gray-300); */
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  width: 1918px;
  height: 993px;
  font-family: var(--font-dm-sans);
}
.image-1-icon,
.resume-1-child {
  position: absolute;
  top: 0;
  height: 704px;
}
.image-1-icon {
  left: 0.5px;
  width: 545.5px;
  object-fit: cover;
}
.resume-1-child {
  left: 0;
  width: 546px;
}
.resume-1 {
  position: relative;
  width: 546px;
  height: 704px;
}
.image-2-icon,
.resume-2-child {
  position: absolute;
  top: 0;
  height: 704px;
}
.image-2-icon {
  left: 0;
  width: 507px;
  object-fit: cover;
}
.resume-2-child {
  left: 507px;
  width: 495.9px;
}
.resume-2 {
  position: relative;
  width: 507px;
  height: 704px;
}
.image-3-icon,
.resume-3-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 506px;
  height: 704px;
}
.resume-3-child {
  background-color: var(--color-white);
}
.image-3-icon {
  object-fit: cover;
}
.resume-3 {
  position: relative;
  width: 506px;
  height: 704px;
}
.resumetemplates1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-14xl);
  gap: var(--gap-30xl);
}
.arrow-container-icon {
  position: relative;
  width: 22.6px;
  height: 23px;
  right: 18px;
}
.take-me-to {
  position: absolute;
  top: 12px;
  left: 9px;
}
.text-container4 {
  position: relative;
  width: 194px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}
.moretemplates {
  border-radius: var(--br-45xl);
  border: 1px solid var(--color-gray-500);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: var(--padding-mid) var(--padding-10xs);
  padding: 8px; */
    padding-left: 15px;
    padding-right: 15px;



}
.content-container1,
.title-conatiner {
  position: absolute;
  display: flex;
  align-items: center;
}
.content-container1 {
  width: 100%;
  top: 314px;
  right: 0;
  left: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 var(--padding-69xl);
  box-sizing: border-box;
  gap: var(--gap-36xl);
}
.title-conatiner {
  top: 146px;
  left: calc(50% - 158px);
  width: 404px;
  flex-direction: row;
  justify-content: center;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.ball1svg-icon {
  position: relative;
  /* width: 150px;
  height: 150px; */
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.ball-container {
  position: absolute;
  width: 10.52%;
  top: 57px;
  right: 81.17%;
  left: 8.31%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xl) var(--padding-7xl);
  box-sizing: border-box;
}
.resumetemplates {
  position: absolute;
  width: 100%;
  top: 10755px;
  right: -2px;
  left: 2px;
  background-color: var(--color-white);
  height: 1161px;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
}
.auto-apply {
  position: absolute;
  top: 17px;
  left: 0;
  line-height: 62px;
}
.text-container5 {
  position: absolute;
  top: 50px;
  left: 351px;
  width: 242px;
  height: 96px;
  overflow: hidden;
}
.partners {
  position: relative;
  line-height: 62px;
}
.yellow-badge {
  position: absolute;
  top: 67px;
  left: 621px;
  border-radius: 0 var(--br-xl) 0 0;
  background-color: var(--color-goldenrod);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-base);
}
.heading-container1 {
  position: absolute;
  top: 0;
  left: calc(50% - 585px);
  width: 1154px;
  height: 170px;
}
.q-image-icon {
  position: relative;
  border-radius: var(--br-xs);
  width: 100.8px;
  height: 100.8px;
  object-fit: cover;
}
.company1-container {
  position: absolute;
  top: 3px;
  left: 228px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xs) var(--padding-mini);
}
.linkedin-image-icon {
  position: relative;
  width: 100.8px;
  height: 100.8px;
  object-fit: cover;
}
.company2-container,
.company3-container,
.company4-container {
  position: absolute;
  top: 0;
  left: 433.8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-smi);
}
.company3-container,
.company4-container {
  top: 3px;
  left: 635.6px;
  padding: var(--padding-2xs) var(--padding-lgi);
}
.company4-container {
  left: 849.4px;
  padding: var(--padding-2xs) var(--padding-xs);
}
.company5-container {
  top: 3px;
  left: 1049.2px;
  padding: var(--padding-2xs) var(--padding-base);
}
.company5-container,
.company6-container,
.company7-container {
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.company6-container {
  top: 3px;
  left: 1257px;
  padding: var(--padding-2xs) 0;
}
.company7-container {
  top: 14px;
  left: 1432.8px;
}
.rolling-companies {
  position: absolute;
  top: 240px;
  left: calc(50% - 869.8px);
  width: 1761.6px;
  height: 128.8px;
  overflow-x: auto;
}
.autoapply {
  position: absolute;
  width: 100%;
  top: 10275px;
  right: 1px;
  left: -1px;
  /* background-color: var(--color-gray-400); */
  height: 480px;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
}
.video-container,
.video-recruiter-demo-icon {
  position: absolute;
  width: 1107.7px;
  height: 981.8px;
}
.video-recruiter-demo-icon {
  top: 0;
  left: 0.1px;
  object-fit: cover;
}
.video-container {
  top: 320px;
  left: calc(50% - 554.1px);
  overflow: hidden;
}
.take-me-to1 {
  /* position: absolute; */
  top: 12px;
  left: 0;
}
.button-text-container {
  position: absolute;
  top: 10px;
  left: 54px;
  width: 159px;
  height: 24px;
  overflow: hidden;
}
.arrow-container-icon1 {
  position: absolute;
  top: 10px;
  left: 273px;
  width: 22.6px;
  height: 23px;
}
.button-container {
  position: absolute;
  top: calc(50% + 611.5px);
  left: calc(50% - 175px);
  border-radius: var(--br-45xl);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 349.6px;
  height: 44px;
  cursor: pointer;
}
.ball2svg-icon {
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.yellow-ball-image-container {
  position: absolute;
  top: 1178px;
  left: calc(50% + 743px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 21.999998092651367px;
}
.ball3svg-icon {
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.green-ball-image-container {
  position: absolute;
  top: 135px;
  left: calc(50% - 869px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 19.999996185302734px 30.9999942779541px;
}
.heading-22 {
  position: absolute;
  top: 26px;
  left: calc(50% - 254.5px);
  line-height: 62px;
}
.heading-container2 {
  position: absolute;
  top: 115px;
  left: calc(50% - 237px);
  width: 579px;
  height: 114px;
  overflow: hidden;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.smartjobseekersdemo {
  position: absolute;
  top: 8894px;
  left: calc(50% - 961px);
  width: 1920px;
  height: 1381px;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
}
.bg-ractangle-shapepng-icon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 1107px;
  object-fit: cover;
}
.video-recruiter-demo-icon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 801px;
  height: 710px;
  object-fit: cover;
}
.video-container1 {
  position: relative;
  width: 801px;
  height: 710px;
  overflow: hidden;
  flex-shrink: 0;
}
.heading-23,
.watch-this-demo {
  position: absolute;
  display: flex;
  align-items: center;
}
.watch-this-demo {
  top: 196.2px;
  left: 212px;
  line-height: 24px;
  text-align: left;
  width: 458.9px;
}
.heading-23 {
  top: 99.2px;
  left: 162px;
  font-size: var(--font-size-21xl);
  line-height: 62px;
  font-family: var(--font-montserrat-alternates);
  color: var(--color-gray-200);
  justify-content: center;
  width: 571px;
  height: 49px;
}
.take-me-to2 {
  /* position: absolute; */
  top: 9.5px;
  left: 26px;
}
.button-text-container1 {
  position: absolute;
  top: 7.9px;
  left: 30.7px;
  width: 125.7px;
  height: 19px;
  overflow: hidden;
}
.arrow-container-icon2 {
  position: absolute;
  top: 7.9px;
  left: 176.8px;
  width: 17.8px;
  height: 18.2px;
}
.button-container1 {
  position: absolute;
  top: calc(50% + 68px);
  left: calc(50% - 151.5px);
  border-radius: 50.59px;
  border: 0.8px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 235.3px;
  height: 35.8px;
  font-size: 12.65px;
  color: var(--color-black);
}
.content-container3 {
  position: relative;
  width: 728px;
  height: 504px;
}
.ball-container1,
.content-container2 {
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-container2 {
  top: calc(50% - 446.5px);
  left: calc(50% - 916.5px);
  width: 1829px;
  padding: var(--padding-7xl) var(--padding-131xl);
  box-sizing: border-box;
}
.ball-container1 {
  top: 12px;
  right: 50px;
  padding: var(--padding-2xl) var(--padding-7xl);
}
.image-background-container {
  position: absolute;
  width: calc(100% + 2px);
  top: 0;
  right: -2px;
  left: 0;
  height: 1107px;
  overflow: hidden;
}
.recruiterdemo {
  position: absolute;
  width: 100%;
  top: 7787px;
  right: 0;
  left: 0;
  background-color: var(--color-white);
  height: 1107px;
  color: var(--color-midnightblue-200);
  font-family: var(--font-inter);
}
.countries-worldwide,
.heading-24 {
  position: relative;
  line-height: 58.4px;
  display: flex;
  align-items: center;
  width: 78px;
  height: 49px;
  flex-shrink: 0;
}
.countries-worldwide {
  font-size: var(--font-size-lg);
  line-height: 40px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-gray-200);
  width: 179.2px;
  height: 24px;
}
.countries-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) 0;
  gap: var(--gap-lg);
}
.k {
  position: absolute;
  top: 4px;
  left: 0;
  line-height: 58.4px;
  display: flex;
  align-items: center;
  width: 94.8px;
  height: 49px;
}
.heading-25,
.registered-user {
  position: relative;
  width: 148px;
  height: 58px;
}
.registered-user {
  font-size: var(--font-size-lg);
  line-height: 40px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
  height: 24px;
  flex-shrink: 0;
}
.users-container {
  width: 148px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.k1 {
  position: absolute;
  top: 4px;
  left: 0;
  line-height: 58.4px;
  display: flex;
  align-items: center;
  width: 77.1px;
  height: 49px;
}
.heading-26,
.small-big {
  position: relative;
  width: 210px;
  height: 58px;
}
.small-big {
  font-size: var(--font-size-lg);
  line-height: 40px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
  width: 195.8px;
  height: 24px;
  flex-shrink: 0;
}
.companies-container {
  width: 210px;
  flex-direction: column;
  gap: var(--gap-smi);
}
.companies-container,
.content-container4,
.customers-stats-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-container4 {
  flex-direction: row;
  padding: var(--padding-21xl) 0px;
  gap: var(--gap-267xl);
}
.customers-stats-container {
  position: absolute;
    top: 1211px;
    left: calc(50% - 959px);
    border-top: 1px solid var(--color-gray-500);
    border-bottom: 1px solid var(--color-gray-500);
    box-sizing: border-box;
    width: 1920px;
    height: 289px;
    flex-direction: column;
    align-items: center;
    padding: var(--padding-28xl) 0px;
    justify-content: center;
}
.bg-ractangle-shapepng-icon1 {
  position: absolute;
  width: calc(100% + 3px);
  top: 0;
  right: -3px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 1211px;
  object-fit: cover;
}
.ball-container2 {
  position: absolute;
  top: 250px;
  left: calc(50% - 814px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.arrow-left-blacksvg {
  position: absolute;
  right: 70px;
  bottom: 10px;
  width: 20px;
  height: 20px;
}
.heading-64 {
  top: 35px;
  left: 0px;
  line-height: 30px;
  color: var(--color-darkslategray-100);
  width: 108.3px;
  height: 20px;
  font-size: large;
}
.div,
.heading-5,
.heading-64 {
  position: absolute;
  display: flex;
  align-items: center;
}
.heading-5 {
  top: -10px;
  left: 0;
  font-size: var(--font-size-lg);
  line-height: 30px;
  width: 308px;
}
.div {
  top: 15px;
  left: 523px;
  letter-spacing: 3px;
  line-height: 25px;
  font-weight: 500;
  width: 32.2px;
  height: 20px;
}
.arrow-right-blacksvg-icon {
  position: absolute;
  right: 42px;
  bottom: 26px;
  width: 20px;
  height: 20px;
}
.arrow-left-blacksvg-icon {
  position: absolute;
  top: calc(50% - 14px);
  left: calc(50% + 179.5px);
  width: 20px;
  height: 14.7px;
  overflow: hidden;
}
.divcustomers-name,
.we-use-smart {
  position: absolute;
  top: 359px;
  left: 5px;
  width: 627px;
  height: 62px;
}
.we-use-smart {
  top: 7px;
  left: 0;
  line-height: 45px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 589px;
  height: 209px;
}
.p {
  position: absolute;
  top: 90px;
  left: 7px;
  width: 630px;
  height: 234px;
  font-size: var(--font-size-5xl);
}
.divhappy-customer-left {
  position: absolute;
  top: 38px;
  left: 10px;
  width: 668px;
  height: 422px;
}
.emojisvg-icon {
  position: relative;
  width: 435px;
  height: 411px;
  overflow: hidden;
  flex-shrink: 0;
}
.emojisvg {
  position: absolute;
  top: calc(50% - 182.5px);
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.pseudo {
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: var(--br-30xl);
  background-color: var(--color-whitesmoke);
  width: 98px;
  height: 98px;
}
.happy-customers1png-icon {
  position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  border-radius: var(--br-21xl);
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.divslider-img {
  position: absolute;
  top: 0;
  left: 176px;
  border-radius: var(--br-32xl);
  width: 102px;
  height: 102px;
  overflow: hidden;
}
.divhalfcircle,
.divhalfclip {
  position: absolute;
  height: 100%;
}
.divhalfcircle {
  width: calc(100% + 51px);
  top: 51px;
  right: 21.1px;
  bottom: -51px;
  left: -72.1px;
  border-radius: var(--br-32xl);
  border: 25px solid var(--color-gray-200);
  box-sizing: border-box;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}
.divhalfclip {
  width: calc(100% - 51px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 51px;
  overflow: hidden;
}
.divslider-img1,
.divslider-img2,
.divslider-img3 {
  position: absolute;
  top: 122px;
  left: 53.5px;
  border-radius: var(--br-32xl);
  width: 102px;
  height: 102px;
  overflow: hidden;
}
.divslider-img2,
.divslider-img3 {
  left: 298.5px;
}
.divslider-img3 {
  top: 244px;
  left: 176px;
}
.divslick-track {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: calc(50% - 234.5px);
  width: 470px;
}
.divslick-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 481px;
  height: 400px;
  overflow: hidden;
}
.divhappy-customer-right {
  position: absolute;
  top: 0;
  left: 715px;
  width: 499px;
  height: 419px;
}
.divrow {
  position: absolute;
  width: calc(100% - 115px);
  top: 201px;
  right: 115px;
  left: 0;
  height: 572px;
}
.heading-27,
.heading-51 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 58.4px;
  display: flex;
  align-items: center;
  width: 322.5px;
  height: 58px;
}
.heading-51 {
  top: 71px;
  font-size: var(--font-size-lg);
  line-height: 26px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 289px;
  height: 24px;
}
.divhappy-customers-title {
  position: absolute;
  top: 0;
  left: 15px;
  width: 1170px;
  height: 96.4px;
  font-size: var(--font-size-21xl);
  font-family: var(--font-jost);
}
.content-container5 {
  position: absolute;
  top: 294px;
  left: calc(50% - 628px);
  width: 1300px;
  height: 917px;
}
.rectangle-image-container {
  position: absolute;
  width: 100%;
  top: 2px;
  right: -1px;
  left: 1px;
  height: 1211px;
  overflow: hidden;
  font-size: var(--font-size-mini);
  color: var(--color-gray-200);
  font-family: var(--font-dm-sans);
}
.happy-customers {
  position: absolute;
  width: 100%;
  top: 6287px;
  right: 0;
  left: 0;
  background-color: var(--color-white);
  height: 1500px;
  text-align: left;
  font-size: var(--font-size-21xl);
}
.ball1svg {
  position: absolute;
  top: 79px;
  left: calc(50% - 794px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.img-icon {
  position: relative;
  width: 18px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.no-cards-required {
  position: relative;
  line-height: 30px;
  font-weight: 500;
}
.item,
.item1,
.item2 {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.item1,
.item2 {
  left: 185.4px;
}
.item2 {
  left: 356.3px;
}
.list {
  position: relative;
  width: 556.1px;
  height: 30px;
}
.card-offers {
  position: absolute;
  bottom: 94px;
  left: calc(50% - 319px);
  border-radius: var(--br-16xl);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-57xl);
}
.start-for-free {
  position: absolute;
  top: 18px;
  left: 40px;
  line-height: 30px;
  font-size: 17px;
}
.button {
  position: absolute;
  top: 517px;
  left: 99px;
  border-radius: var(--br-81xl);
  background-color: var(--color-dodgerblue-100);
  width: 187.4px;
  height: 66px;
  overflow: hidden;
  text-align: center;
  color: var(--color-white);
  cursor: pointer;
}
.item-unlimited,
.item-unlimited1,
.item-unlimited2,
.item-unlimited3 {
  position: absolute;
  top: 0;
  left: 24px;
  line-height: 30px;
  font-weight: 500;
}
.item-unlimited1,
.item-unlimited2,
.item-unlimited3 {
  top: 38px;
}
.item-unlimited2,
.item-unlimited3 {
  top: 76px;
}
.item-unlimited3 {
  top: 114px;
}
.item-200,
.item-unlimited4,
.item-unlimited5,
.item-unlimited6 {
  position: absolute;
  top: 152px;
  left: 24px;
  line-height: 30px;
  font-weight: 500;
}
.item-unlimited4,
.item-unlimited5,
.item-unlimited6 {
  top: 190px;
}
.item-unlimited5,
.item-unlimited6 {
  top: 228px;
}
.item-unlimited6 {
  top: 266px;
}
.list1 {
  position: absolute;
  top: 159px;
  left: 47px;
  width: 318px;
  height: 296px;
  color: var(--color-darkslategray-100);
}
.compare-price-shapesvg-icon {
  position: relative;
  width: 86px;
  height: 86px;
  overflow: hidden;
  flex-shrink: 0;
}
.compare-price-shapesvg {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.crownsvg-icon {
  position: relative;
  width: 22px;
  height: 19px;
  overflow: hidden;
  flex-shrink: 0;
}
.crownsvg {
  position: absolute;
  top: calc(50% - 8.2px);
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.icons {
  position: absolute;
  top: calc(50% - 234.5px);
  left: calc(50% + 68px);
  width: 86px;
  height: 86px;
}
.strong-19 {
  top: 0;
  left: 11px;
  font-size: var(--font-size-17xl);
  width: 37.3px;
  height: 44px;
}
.per-month,
.strong-19,
.superscript {
  position: absolute;
  line-height: 26px;
  display: flex;
  align-items: center;
}
.per-month {
  top: 20px;
  left: 55.3px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  width: 88.1px;
  height: 24px;
}
.superscript {
  top: -6px;
  left: 0;
  font-weight: 600;
  width: 11.5px;
  height: 22px;
}
.heading-28,
.heading-52 {
  position: absolute;
  left: 41px;
  font-size: var(--font-size-lg);
}
.heading-28 {
  top: 100px;
  width: 154.3px;
  height: 44px;
  font-family: var(--font-montserrat-alternates);
}
.heading-52 {
  top: 42px;
  line-height: 26px;
  display: flex;
  align-items: center;
  width: 164px;
  height: 30px;
}
.jobseeker-price-card {
  position: absolute;
  width: calc(100% - 774px);
  top: 0;
  right: 602px;
  left: -19px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  height: 611px;
  /* cursor: pointer; */
}
.button1 {
  position: absolute;
  top: 513px;
  left: 68px;
  border-radius: var(--br-81xl);
  background-color: var(--color-dodgerblue-100);
  width: 187.4px;
  height: 66px;
  overflow: hidden;
  text-align: center;
  color: var(--color-white);
}
.item-email,
.item-unlimited7 {
  position: absolute;
  top: 5px;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.item-unlimited7 {
  top: 42px;
}
.item-great,
.item-unlimited10,
.item-unlimited8,
.item-unlimited9 {
  position: absolute;
  top: 79px;
  left: 0;
  line-height: 30px;
  font-weight: 500;
}
.item-great,
.item-unlimited10,
.item-unlimited9 {
  top: 116px;
}
.item-great,
.item-unlimited10 {
  top: 153px;
}
.item-unlimited10 {
  top: 190px;
}
.list2 {
  position: absolute;
  top: 159px;
  left: 41px;
  width: 287px;
  height: 225px;
  color: var(--color-darkslategray-100);
}
.compare-price-shapesvg1 {
  position: absolute;
  top: calc(50% - 43px);
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.crown-2svg-icon {
  position: relative;
  width: 22px;
  height: 22px;
  overflow: hidden;
  flex-shrink: 0;
}
.crown-2svg {
  position: absolute;
  top: calc(50% - 9.7px);
  left: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.icons1 {
  position: absolute;
  height: calc(100% - 439px);
  width: calc(100% - 279px);
  top: 28px;
  right: 28px;
  bottom: 411px;
  left: 251px;
}
.per-month1,
.strong-59 {
  position: absolute;
  line-height: 26px;
  display: flex;
  align-items: center;
}
.strong-59 {
  top: -9px;
  left: 11.3px;
  font-size: larger;
  width: 211px;
  height: 44px;
}
.per-month1 {
  top: 8px;
  left: 62.7px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  width: 88.1px;
  height: 24px;
}
.heading-29,
.heading-53 {
  position: absolute;
  left: 41px;
  font-size: var(--font-size-lg);
}
.heading-29 {
  top: 100px;
  width: 300px;
  height: 33px;
  font-family: var(--font-montserrat-alternates);
}
.heading-53 {
  top: 42px;
  line-height: 26px;
  display: flex;
  align-items: center;
  width: 141px;
  height: 24px;
}
.recruiter-price-card {
  position: absolute;
  width: calc(100% - 763px);
  top: 0;
  right: 148px;
  left: 389px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  height: 611px;
}
.university-price-card {
  position: absolute;
  width: calc(100% - 763px);
  top: 0;
  right: 148px;
  left: 809px;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  height: 611px;
}
.price-card-container {
  position: absolute;
  top: calc(50% - 197.5px);
  left: calc(50% - 552px);
  width: 1154px;
  height: 611px;
  color: var(--color-gray-200);
  z-index: 1;
}
.button-monthly {
  position: absolute;
  top: 4.5px;
  left: 15px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56.4px;
  height: 20px;
}
.text-container6 {
  position: absolute;
  top: 0.5px;
  left: 250px;
  width: 85px;
  height: 29px;
  overflow: hidden;
}
.input,
.pseudo4 {
  position: absolute;
  top: 5px;
  left: 6px;
  border-radius: var(--br-6xs);
  background-color: var(--color-dodgerblue-200);
  width: 14px;
  height: 14px;
}
.input {
  top: 3px;
  left: 355px;
  border-radius: var(--br-mini);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  box-sizing: border-box;
  width: 70px;
  height: 24px;
}
.off {
  color: var(--color-darkturquoise);
}
.yearly-20-off-container1 {
  line-break: anywhere;
  width: 100%;
}
.yearly-20-off-container {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 137px;
}
.button2 {
  position: absolute;
  top: 0;
  left: 445px;
  width: 113px;
  height: 30px;
}
.yearly-payment-tab {
  position: absolute;
  top: 336px;
  left: calc(50% - 378px);
  width: 808px;
  height: 30px;
  text-align: center;
}
.pricing-table {
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
 
}
.top-heading-container {
  position: absolute;
  top: 0;
  left: 327px;
  width: 456px;
  height: 65px;
  overflow: hidden;
  font-size: var(--font-size-lg);
  color: var(--color-dodgerblue-100);
  font-family: var(--font-dm-sans);
}
.giving-you-good {
  position: absolute;
  top: 14px;
  left: 17px;
  line-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 475.8px;
  height: 49px;
}
.heading1-container {
  position: absolute;
  top: 82px;
  left: 313px;
  width: 546px;
  height: 68px;
  overflow: hidden;
}
.with {
  position: absolute;
  top: 20px;
  left: 91px;
  line-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 107.3px;
  height: 49px;
}
.heading2-container {
  position: absolute;
  top: 167px;
  left: 282px;
  width: 546px;
  height: 90px;
  overflow: hidden;
}
.best-service {
  position: absolute;
  top: 0;
  left: 10px;
  line-height: 62px;
}
.yellow-badge1 {
  position: absolute;
  top: 181px;
  left: 496px;
  border-radius: 0 var(--br-xl) 0 0;
  background-color: var(--color-goldenrod);
  width: 271px;
  height: 62px;
}
.heading-container3,
.pricing1 {
  position: absolute;
  top: 56px;
  left: calc(50% - 552px);
  width: 1110px;
  height: 257px;
  text-align: center;
  font-size: var(--font-size-21xl);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.pricing1 {
  top: 5114px;
  left: calc(50% - 956px);
  width: 1920px;
  height: 1173px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-darkslategray-100);
  font-family: var(--font-dm-sans);
}
.ball2svg {
  position: absolute;
  width: 4.17%;
  top: calc(50% - 23px);
  right: 13.75%;
  left: 82.08%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.divdividing-line {
  position: absolute;
  top: calc(50% - 1189px);
  left: calc(50% + 30px);
  background-color: var(--color-gray-500);
  width: 6px;
  height: 2437px;
}
.heading-210,
.product1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product1 {
  top: 36px;
  left: 533px;
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
  width: 196.3px;
  height: 24px;
}
.heading-210 {
  top: 99px;
  left: 345px;
  font-size: var(--font-size-21xl);
  line-height: 62px;
  font-family: var(--font-montserrat-alternates);
  color: var(--color-gray-200);
  width: 571px;
  height: 49px;
}
.title-container {
  position: absolute;
  top: 30px;
  left: calc(50% - 631px);
  width: 1262px;
  height: 197px;
}
.integrate-emails-container-icon {
  position: absolute;
  top: 72px;
  left: 54px;
  width: 379.9px;
  height: 250px;
  object-fit: cover;
}
.b,
.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 503px;
  height: 394px;
}
.b {
  top: 11px;
  left: 17px;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 6.1px;
  height: 19px;
}
.divpowerfull-template-number {
  position: absolute;
  top: 0;
  left: 535px;
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-100);
  border: 10px solid var(--color-white);
  box-sizing: border-box;
  width: 60px;
  height: 60px;
}
.heading-4 {
  position: absolute;
  top: 52px;
  left: 51px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.integrate-outlook-g {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 354px;
  flex-shrink: 0;
}
.sub-content {
  position: absolute;
  top: 119px;
  left: 51px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
  color: var(--color-darkslategray-100);
}
.item3,
.item4 {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-mini);
}
.item3 {
  display: flex;
  justify-content: flex-start;
  /* padding: 0 var(--padding-104xl) 0 0; */
}
.item4 {
  width: 458px;
  /* padding: 0 var(--padding-186xl) 0 0; */
  box-sizing: border-box;
}
.item4,
.item5,
.list3 {
  display: flex;
  justify-content: flex-start;
}
.item5 {
  flex-direction: row;
  align-items: center;
  /* padding: 0 var(--padding-237xl) 0 0; */
  gap: var(--gap-mini);
}
.list3 {
  position: absolute;
  top: 240px;
  left: 51px;
  width: 440px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8xs);
}
.integrate-emails-content-conta {
  position: absolute;
  top: 0;
  left: 662px;
  width: 511px;
  height: 392px;
  font-family: var(--font-dm-sans);
}
.integrate-emails-row {
  position: absolute;
  width: calc(100% - 68px);
  top: calc(50% - 1212.5px);
  right: 68px;
  left: 0;
  height: 394px;
}
.heading-41 {
  position: relative;
  line-height: 35.04px;
}
.heading-65 {
  position: absolute;
  top: 10px;
  left: 70px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-30xl) 0.779998779296875px 0;
  font-size: var(--font-size-5xl);
  font-family: var(--font-montserrat-alternates);
}
.maintain-candidates-resumes {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 360px;
  flex-shrink: 0;
}
.subcontent {
  position: absolute;
  top: 82.8px;
  left: 70px;
  padding: 0 var(--padding-base) 0 0;
  color: var(--color-darkslategray-100);
}
.monitor-iconsvg,
.subcontent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.all-candidates-profiles {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 108px;
}
.item6,
.item7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.item6 {
  padding: 0.2899999916553497var (--padding-10xs) 3px 0 0;
}
.item7 {
  padding: 0.28999999165534973px var(--padding-8xl) 0 0;
}
.centralized-job-vacancies {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 88px;
}
.item8,
.ordered-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.item8 {
  flex-direction: column;
  padding: 0.28999999165534973px var(--padding-28xl) 0 0;
  gap: var(--gap-3xs);
}
.ordered-list {
  position: absolute;
  top: 178.8px;
  left: 70px;
  flex-direction: row;
  gap: var(--gap-xl);
}
.b1,
.content-container7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 491px;
  height: 396px;
}
.b1 {
  top: 20px;
  left: 25.6px;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 9.1px;
  height: 19px;
}
.divpowerfull-template-number1 {
  position: absolute;
  top: 0;
  left: 535px;
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro-100);
  border: 10px solid var(--color-white);
  box-sizing: border-box;
  width: 60px;
  height: 60px;
  font-family: var(--font-montserrat-alternates);
}
.centralised-database-image-icon {
  position: absolute;
  top: 14px;
  left: 49px;
  width: 416px;
  height: 329px;
  object-fit: cover;
}
.image-container1 {
  position: absolute;
  top: 0;
  left: 670px;
  width: 534px;
  height: 358px;
  overflow: hidden;
}
.centralised-database {
  position: absolute;
  width: 100%;
  top: calc(50% - 812.5px);
  right: 0;
  left: 0;
  height: 396px;
  font-family: var(--font-dm-sans);
}
.sends-assesment-image-icon {
  position: absolute;
  top: 53px;
  left: 37px;
  width: 423px;
  height: 294px;
  object-fit: cover;
}
.image-container2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 510px;
  height: 405px;
}
.b2,
.heading-42 {
  position: absolute;
}
.b2 {
  top: 20px;
  left: 25.5px;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 9.1px;
  height: 19px;
}
.heading-42 {
  top: 75px;
  left: 57px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.once-the-profile {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 442px;
  height: 104px;
  flex-shrink: 0;
}
.p1 {
  position: absolute;
  top: 134px;
  left: 57px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-4xl) 0 0;
  color: var(--color-darkslategray-100);
}
.item10,
.list4,
.p1 {
  display: flex;
  justify-content: flex-start;
}
.item10 {
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-131xl) 0 0;
  gap: var(--gap-mini);
}
.list4 {
  position: absolute;
  top: 238px;
  left: 56.9px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8xs);
}
.content-container8 {
  position: absolute;
  top: 0;
  left: 678px;
  width: 563px;
  height: 405px;
  font-family: var(--font-dm-sans);
}
.send-assessments {
  position: absolute;
  width: 100%;
  top: calc(50% - 416.5px);
  right: 0;
  left: 0;
  height: 405px;
}
.heading-43 {
  position: absolute;
  top: 75px;
  left: 66px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.profiles-are-shortlisted {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 358px;
  flex-shrink: 0;
}
.p2 {
  position: absolute;
  top: 134px;
  left: 66px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-3xs) 0 0;
  color: var(--color-darkslategray-100);
}
.item13,
.list5,
.p2 {
  display: flex;
  justify-content: flex-start;
}
.item13 {
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-186xl) 0 0;
  gap: var(--gap-mini);
}
.list5 {
  position: absolute;
  top: 272px;
  left: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8xs);
}
.content-container9 {
  position: absolute;
  top: 0;
  left: 0;
  width: 498px;
  height: 455px;
}
.b3 {
  position: absolute;
  top: 15.3px;
  left: 24px;
  line-height: 30px;
}
.shortlist-profiles-image-icon {
  position: absolute;
  top: 38px;
  left: 57px;
  width: 416px;
  height: 332px;
  object-fit: cover;
}
.image-container3 {
  position: absolute;
  top: 0;
  left: 681px;
  width: 521px;
  height: 396px;
}
.shortlist-profiles {
  position: absolute;
  width: 100%;
  top: calc(50% - 11.5px);
  right: 0;
  left: 0;
  height: 455px;
  font-family: var(--font-dm-sans);
}
.schedule-interview-image-icon {
  position: absolute;
  top: 68px;
  left: 26px;
  width: 433.5px;
  height: 297.1px;
  object-fit: cover;
}
.image-container4 {
  position: absolute;
  top: 0;
  left: 33px;
  width: 485.5px;
  height: 433.1px;
}
.b4,
.heading-44 {
  position: absolute;
}
.b4 {
  top: 20.2px;
  left: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 9.1px;
  height: 19px;
}
.heading-44 {
  top: 75px;
  left: 56px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.interviewrs-profiles-can {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 401px;
  flex-shrink: 0;
}
.heading-66 {
  position: absolute;
  top: 133px;
  left: 56px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-30xl) 0.779998779296875px 0;
}
.auto-interview-slot {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 111px;
}
.item15 {
  width: 136px;
  height: 61px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.2899999916553497var (--padding-10xs) 3px 0 0;
  box-sizing: border-box;
  gap: var(--gap-3xs);
}
.auto-emails-for,
.reminders-before-interviews {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.auto-emails-for {
  width: 135px;
  height: 76px;
  flex-shrink: 0;
}
.reminders-before-interviews {
  width: 113px;
}
.ordered-list1 {
  position: absolute;
  top: 294px;
  left: 56px;
  width: 482px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.content-container10 {
  position: absolute;
  top: 0;
  left: 698px;
  width: 504px;
  height: 416px;
  font-family: var(--font-dm-sans);
}
.schedule-interviews {
  position: absolute;
  width: 100%;
  top: calc(50% + 443.5px);
  right: 0;
  left: 0;
  height: 433px;
}
.heading-45 {
  position: absolute;
  top: 45px;
  left: 21px;
  font-size: var(--font-size-5xl);
  line-height: 35.04px;
  font-family: var(--font-montserrat-alternates);
}
.after-the-feedback {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 335px;
  flex-shrink: 0;
}
.list6,
.p3 {
  position: absolute;
  left: 21px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.p3 {
  top: 113px;
  flex-direction: row;
  padding: 0 var(--padding-4xl) 0 0;
  color: var(--color-darkslategray-100);
}
.list6 {
  top: 205px;
  width: 437px;
  flex-direction: column;
  gap: var(--gap-8xs);
}
.content-container11 {
  position: absolute;
  top: 0;
  left: 35px;
  width: 479px;
  height: 350px;
  overflow: hidden;
}
.b5 {
  position: absolute;
  top: 20.1px;
  left: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  width: 9.1px;
  height: 19px;
}
.send-results-image-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 486px;
  height: 355px;
  object-fit: cover;
}
.image-container5 {
  position: absolute;
  top: -3px;
  left: 695px;
  width: 509px;
  height: 355px;
}
.send-invitation {
  position: absolute;
  width: 100%;
  top: calc(50% + 876.5px);
  right: 0;
  left: 0;
  height: 355px;
  font-family: var(--font-dm-sans);
}
.product-recruiters-content {
  position: absolute;
  width: calc(100% - 21px);
  top: 272px;
  right: 21px;
  left: 0;
  height: 2425px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-gray-200);
  font-family: var(--font-montserrat-alternates);
}
.content-container6 {
  position: absolute;
  top: 17px;
  left: calc(50% - 586px);
  width: 1262px;
  height: 2757px;
}
.product-recruiter {
  position: absolute;
  width: 100%;
  top: 2196px;
  right: -3px;
  left: 3px;
  background-color: var(--color-gray-600);
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  height: 2918px;
  overflow: hidden;
  font-size: var(--font-size-13xl);
  font-family: var(--font-dm-sans);
}
.heading-54,
.prepare-for-live {
  position: relative;
  line-height: 26.28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 169px;
  height: 22px;
  flex-shrink: 0;
}
.prepare-for-live {
  font-size: var(--font-size-mini);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 318.4px;
  height: 80px;
}
.text-container7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-11xl) var(--padding-base);
  gap: var(--gap-41xl);
}
.interview-prep-image-icon {
  position: relative;
  width: 91px;
  height: 108px;
  object-fit: cover;
}
.image-container6 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-smi);
}
.interview-preparation {
  border-radius: var(--br-11xl);
  background-color: var(--color-ghostwhite);
  width: 384px;
  height: 388px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-mini);
  box-sizing: border-box;
}
.get-career-advise,
.heading-55 {
  position: absolute;
  top: 35.8px;
  left: 101px;
  line-height: 26.28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128.6px;
  height: 22px;
}
.get-career-advise {
  top: 121.8px;
  left: 6px;
  font-size: var(--font-size-mini);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 318.4px;
  height: 80px;
}
.text-container8 {
  position: absolute;
  top: 21px;
  left: 20px;
  width: 330.4px;
  height: 202px;
}
.career-advise-image-icon {
  position: absolute;
  top: 19.8px;
  left: 0;
  width: 108px;
  height: 128px;
  object-fit: cover;
}
.image-container7 {
  position: absolute;
  top: 234px;
  left: 131.2px;
  width: 108px;
  height: 128px;
  overflow: hidden;
}
.career-advise {
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--color-white);
  width: 370.4px;
  height: 383px;
  overflow: hidden;
  flex-shrink: 0;
  /* background-color: #CAF5FA; */

}
.heading-56,
.relax-while-our {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-56 {
  top: 48px;
  left: 104px;
  line-height: 26.28px;
  width: 125px;
  height: 22px;
}
.relax-while-our {
  top: 120px;
  left: 8px;
  font-size: var(--font-size-mini);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 318.4px;
}
.text-conatiner {
  position: relative;
  width: 333px;
  height: 220px;
}
.autojob-apply-image-icon {
  position: relative;
  width: 116px;
  height: 112px;
  object-fit: cover;
}
.auto-apply1,
.image-container8 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
}
.auto-apply1 {
  border-radius: var(--br-11xl);
  background-color: var(--color-ghostwhite);
  width: 373px;
  height: 383px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-lg);
  box-sizing: border-box;
  gap: var(--gap-lgi);
}
.job-seeker-product-second-row {
  position: absolute;
  width: 100%;
  top: 440.5px;
  right: 0;
  left: 0;
  height: 387.8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-11xl);
}
.heading-57,
.text-container9 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-57 {
  position: relative;
  line-height: 26.28px;
  width: 214px;
  height: 22px;
  flex-shrink: 0;
}
.text-container9 {
  height: 224px;
  flex-direction: column;
  padding: var(--padding-9xl) var(--padding-9xs) var(--padding-14xl);
  box-sizing: border-box;
  gap: var(--gap-42xl);
}
.resume-builder-image-icon {
  position: relative;
  width: 125px;
  height: 144px;
  object-fit: cover;
}
.build-and-enhance-resume,
.image-container9 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-2xl);
}
.build-and-enhance-resume {
  border-radius: var(--br-11xl);
  /* background-color: var(--color-white); */
  /* background-color: #CAF5FA; */

  width: 379px;
  height: 388px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-7xs) var(--padding-lgi);
  box-sizing: border-box;
}
.craft-compelling-cover,
.heading-58 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-58 {
  line-height: 26.28px;
  width: 128px;
  height: 78px;
  flex-shrink: 0;
}
.craft-compelling-cover {
  font-size: var(--font-size-mini);
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-dm-sans);
  color: var(--color-darkslategray-100);
  width: 318.4px;
}
.text-container10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
  gap: var(--gap-19xl);
}
.cover-letter-image-icon {
  position: relative;
  width: 111px;
  height: 112.7px;
  object-fit: cover;
}
.image-container10 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.build-cover-letters {
  border-radius: var(--br-11xl);
  background-color: var(--color-ghostwhite);
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-6xs) 0;
  gap: var(--gap-53xl);
}
.build-cover-letters,
.heading-59,
.text-conatiner1 {
  display: flex;
  align-items: center;
}
.heading-59 {
  position: relative;
  line-height: 26.28px;
  justify-content: center;
  width: 194.5px;
  height: 22px;
  flex-shrink: 0;
}
.text-conatiner1 {
  height: 242px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-9xl) var(--padding-5xs) var(--padding-14xl);
  box-sizing: border-box;
  gap: var(--gap-41xl);
}
.job-search-image-icon {
  position: relative;
  width: 115px;
  height: 114px;
  object-fit: cover;
}
.image-container11,
.job-search {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-xs);
}
.job-search {
  border-radius: var(--br-11xl);
  padding: 0 var(--padding-xs);
  /* background-color: #CAF5FA; */

}
.job-seeker-product-first-row {
  position: absolute;
  width: calc(100% - 23.6px);
  top: calc(50% - 414.15px);
  right: 11.6px;
  left: 12px;
  height: 387.8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-11xl);
}
.job-seeker-product-second-row-parent {
  position: absolute;
  width: calc(100% - 702.6px);
  top: 271.7px;
  right: 364.6px;
  left: 338px;
  height: 828.3px;
}
.heading-211,
.products {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.products {
  letter-spacing: 3.6px;
  line-height: 30px;
  text-transform: uppercase;
  width: 196.3px;
  height: 24px;
}
.heading-211 {
  font-size: var(--font-size-21xl);
  line-height: 62px;
  font-family: var(--font-montserrat-alternates);
  color: var(--color-gray-200);
  width: 571px;
  height: 49px;
}
.title-conatiner1 {
  position: absolute;
  top: 106.9px;
  left: calc(50% - 610px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-280xl);
  gap: var(--gap-2xl);
  font-size: var(--font-size-13xl);
  color: #0095ff;
  font-family: var(--font-dm-sans);
}
.ball3svg-icon2 {
  position: relative;
  width: 166.8px;
  height: 166.8px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.ball-container3 {
  position: absolute;
  top: 107px;
  right: 70.6px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 70.07608795166016px 86.76087188720703px;
}
.product-job-seekers {
  position: absolute;
  top: 1008px;
  left: calc(50% - 963px);
  width: 1920px;
  height: 1188px;
  color: var(--color-gray-200);
}
.opening-page-gif-icon {
  position: relative;
  width: 647px;
  height: 662px;
  object-fit: cover;
}
.democratizing-recruitment,
.image-container-2 {
  position: absolute;
  display: flex;
  align-items: center;
}
.image-container-2 {
  top: 78px;
  left: calc(50% + 17.5px);
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-end;
  padding: var(--padding-54xl) var(--padding-74xl);
}
.democratizing-recruitment {
  top: 12px;
  left: 12px;
  line-height: 72px;
  justify-content: center;
  width: 582px;
}
.text-container11 {
  position: absolute;
  top: 0;
  left: calc(50% - 350px);
  width: 606px;
  height: 168px;
  overflow: hidden;
}
.resumebuilder-31-ai-recruitern1 {
  position: absolute;
  top: 102.1px;
  left: 51.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1288.6px;
  height: 352.7px;
}
.text-animation {
  position: absolute;
  top: 84px;
  left: calc(50% - 530px);
  width: 1380px;
  height: 572.4px;
  overflow: hidden;
  font-size: 76.01px;
  color: var(--color-indianred);
  font-family: var(--font-inter);
}
.text-container-parent {
  position: absolute;
  top: 184px;
  left: calc(50% - 1135.5px);
  width: 1380px;
  height: 656.4px;
}
.opening-page,
.website-main {
  position: absolute;
  left: 0;
  overflow: hidden;
}
.opening-page {
  width: calc(100% + 5px);
  top: 2px;
  right: -5px;
  border-radius: 0 0 var(--br-101xl) var(--br-101xl);
  background: linear-gradient(305deg,#ffffff 0%, rgba(190, 225, 236, 0.63)  46.49%, rgba(190, 225, 236, 0.63) 56.47%, rgba(5, 240, 253, 0.00) 121.12%);
  height: 1008px;
  font-size: var(--font-size-41xl);
  color: var(--color-gray-200);
}
.website-main {
  width: 100%;
  top: 113px;
  right: 0;
  background-color: var(--color-white);
  height: 12675px;
  font-size: var(--font-size-lg);
  color: var(--color-dodgerblue-100);
  font-family: var(--font-montserrat-alternates);
}
.all-rights-reserved1,
.item-link,
.item-link1,
.item-link2 {
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 20px;
}
.all-rights-reserved1 {
  position: relative;
  width: 219.6px;
  flex-shrink: 0;
}
.item-link,
.item-link1,
.item-link2 {
  position: absolute;
  top: 5px;
  left: 300.8px;
  width: 153.1px;
}
.item-link1,
.item-link2 {
  left: 483.8px;
  width: 57.4px;
}
.item-link2 {
  left: 571px;
  width: 99.2px;
}
.list7 {
  position: relative;
  width: 670px;
  height: 30px;
}
.all-rights-reserved {
  position: absolute;
  top: 454px;
  left: calc(50% - 608.6px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
  gap: var(--gap-261xl);
}
.heading-67,
.item-link3 {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading-67 {
  font-size: 26.73px;
  line-height: 8.51px;
  text-transform: uppercase;
  font-weight: 600;
  /* font-family: var(--font-montserrat-alternates); */
  color: var(--color-black);
  width: 160.4px;
  height: 26.7px;
}
.item-link3 {
  line-height: 45.07px;
  width: 225.3px;
  height: 38.2px;
}
.products1 {
  position: absolute;
  top: 0;
  left: calc(50% - 281.6px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15.27px;
}
.heading-68,
.item-link7 {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.heading-68 {
  font-size: 26.73px;
  line-height: 8.51px;
  text-transform: uppercase;
  font-weight: 600;
  /* font-family: var(--font-montserrat-alternates); */
  color: var(--color-black);
  width: 190.9px;
  height: 26.7px;
}
.item-link7 {
  line-height: 45.07px;
  width: 252px;
  height: 38.2px;
}
.heading-6-products-parent {
  position: absolute;
  top: 0;
  left: calc(50% + 29.6px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15.27px;
}
.products-parent {
  position: absolute;
  top: 54px;
  left: 668px;
  width: 563.2px;
  height: 294px;
  overflow: hidden;
  font-size: 22.91px;
}
.facebooksvg-icon,
.follow {
  position: relative;
  flex-shrink: 0;
}
.follow {
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 55.6px;
  height: 19px;
}
.facebooksvg-icon {
  width: 19px;
  height: 18px;
  overflow: hidden;
}
.linkedinsvg-icon{
  width: 21px;
  height: 20px;
  overflow: hidden;

}
.instagramsvg-icon {
  position: relative;
  width: 16px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.twittersvg-icon {
  position: relative;
  width: 16px;
  height: 13.5px;
  overflow: hidden;
  flex-shrink: 0;
}

.item-link-linkedin,
.item-link-facebook,
.item-link-twitter,
.item-link-instagram  {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

}

.twittersvg-icon {
  position: relative;
  width: 16px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}


.item-link-facebooksvg {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

}
.pinterestsvg-icon {
  position: relative;
  width: 15.2px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.follow-us,
.list8 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.list8 {
  align-items: flex-start;
  padding: 8.279999732971191px 0 5.720000267028809px;
  gap: var(--gap-9xl);
}
.follow-us {
  position: absolute;
  bottom: 109px;
  left: calc(50% - 600.6px);
  width: 265px;
  align-items: center;
  gap: var(--gap-xl);
  color: var(--color-black);
  font-family: var(--font-montserrat-alternates);
}
.recruiter-robo-logo-3-2-icon {
  position: relative;
  width: 65.5px;
  height: 75.5px;
  object-fit: cover;
}
.logo-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-base);
}
.is-a-dedicated {
  font-size: var(--font-size-mini);
  overflow-wrap: break-word;
}
.recruiter-robo-is-container {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 300px;
  flex-shrink: 0;
}

.website-info-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-10xs) var(--padding-2xs);
}
.logo-container-parent {
  position: absolute;
  top: 0;
  left: calc(50% - 615.6px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-base);
  color: var(--color-gray-800);
}
.all-rights-reserved-parent {
  position: absolute;
  top: 93px;
  left: calc(50% - 654px);
  width: 1231.2px;
  height: 484px;
  overflow: hidden;
}
.content-container12 {
  position: absolute;
  width: 100%;
  right: -2px;
  bottom: 1px;
  left: 2px;
  border-top: 1px solid var(--color-gainsboro-300);
  box-sizing: border-box;
  height: 674px;
}
.green-ball-image-container1 {
  position: absolute;
  top: 169px;
  left: calc(50% - 873.2px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
}
.website-footer {
  position: absolute;
  width: 100%;
  top: 12788px;
  right: -2px;
  left: 2px;
  background-color: var(--color-gray-900);
  height: 675px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-gray-700);
  font-family: var(--font-dm-sans);
}
.landing-page-desktop-19201 {
  background-color: var(--color-white);
  overflow: hidden;
}
.landing-page-desktop-19201,
.new-landing-page-desktop-192 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.desktop-1520plus-made-on-1920 {
  position: relative;
  width: 100%;
  height: 13463px;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-midnightblue-100);
  font-family: var(--font-roboto);
  zoom: 85%;
}
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(500px); /* Adjust the distance as needed */
  }
}

.yball {
  rotate: 0deg;
  float: right;
  margin: 200px 0 0 50px;
  width: 400px; /* Adjust the size as needed */
  height: 100px;
  animation: moveUpDown 6s linear infinite;
}
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(500px); /* Adjust the distance as needed */
  }
}

 

.ball {
  rotate: 0deg;
  float: right;
  margin: 200px 0 0 50px;
  width: 400px; /* Adjust the size as needed */
  height: 100px;
  animation: moveUpDown 6s linear infinite;
}
.social-media-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.social-media-button {
  width: 3rem;
  height: 3rem;
  border: none;
  background: transparent;
  border-radius: 50%;
  outline: 2px solid #000;
  cursor: pointer;
  transition: all 0.3s;
  display: grid;
  place-items: center;
}

.social-media-button:hover {
  outline-offset: 3px;
  transition: all 0.3s;
}

.social-media-button img {
  transition: all 0.3s;
}

.social-media-button:hover img {
  transform: scale(1.15);
}

.social-media-button:hover:nth-child(1) {
  background:  radial-gradient(
      circle at 30% 107%, #fdf497 0%, 
      #fdf497 5%, #fd5949 45%,
      #d6249f 60%,#285AEB 90%
  ); 
}

.social-media-button:hover:nth-child(2) {
  background-color: var(--x);
}

.social-media-button:hover:nth-child(2) svg {
  stroke: #ffffff;
}

.social-media-button:hover:nth-child(3) {
  background-color: var(--youtube);
}
.social-media-button:hover:nth-child(1) svg{
  stroke:var(--white);
}
.social-media-button:hover:nth-child(3) svg{
  stroke:var(--white);
}

.social-media-button:hover:nth-child(4) svg{
  stroke:var(--white);
}


.social-media-button:hover:nth-child(4) {
  background-color: var(--linkedin);
}