.pref1 {
  border-color: #e3e4e8;
}
.password-container {
  width: 400px;
  position: relative;
}
.password-container input[type="password"],
.password-container input[type="text"] {
  width: 100%;
  padding: 12px 36px 12px 12px;
  box-sizing: border-box;
}
.fa-eye {
  position: absolute;
  top: 28%;
  right: 4%;
  cursor: pointer;
  color: lightgray;
}

.password4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.passboarder {
  border-width: 1px 0px 1px 1px;
}
.passboarder2 {
  border-radius: 12px;
  border-width: 1px 1px 1px 1px;
}
/* HTML: <div className="loader"></div> */
/* HTML: <div className="loader"></div> */
.loader {
  --d: 22px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #69c8c2;
  box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
    calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
    calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
    calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
    calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px,
    calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
    calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
  animation: l27 1s infinite steps(8);
}
@keyframes l27 {
  100% {
    transform: rotate(1turn);
  }
}
