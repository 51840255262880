@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@500;700&display=swap");




body {
  margin: 0;
  padding: 0;
  line-height: normal;
  zoom: 0.67;
}

@media screen and (max-width: 1000px){
  ::-webkit-scrollbar {
      -webkit-appearance: none;
  }
}

:root {
  /* fonts */
  --background: #060D23;
    --linkedin: #0A66C2;
    --youtube: #314ca3;
    --x: #0F1419;
    --white: #FFF;
  --font-inter: Inter;
  --font-dm-sans: "DM Sans";
  --font-montserrat-alternates: "Montserrat Alternates";
  --font-jost: Jost;
  --font-rubik: Rubik;
  --font-plus-jakarta-sans: "Plus Jakarta Sans";
  --font-poppins: Poppins;
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --font-size-3xl-9: 22.9px;
  --font-size-7xl-7: 26.7px;
  --font-size-57xl: 76px;
  --font-size-41xl: 60px;
  --font-size-21xl: 40px;
  --font-size-13xl: 32px;
  --font-size-lg: 18px;
  --font-size-5xl: 24px;
  --font-size-17xl: 36px;
  --font-size-smi-7: 12.7px;
  --font-size-lg-5: 18.5px;
  --font-size-6xl-4: 25.4px;
  --font-size-xl: 20px;

  /* Colors */
  --color-indianred: #e86767;
  --color-white: #fff;
  --color-gray-100: #161616;
  --color-gray-200: #111;
  --color-gray-300: rgba(255, 255, 255, 0.9);
  --color-gray-400: rgba(255, 255, 255, 0.95);
  --color-gray-500: rgba(0, 0, 0, 0.1);
  --color-gray-600: rgba(0, 0, 0, 0.02);
  --color-gray-700: rgba(0, 0, 0, 0.7);
  --color-gray-800: rgba(0, 0, 0, 0.75);
  --color-gray-900: rgba(25, 28, 31, 0.1);
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: #d6dae2;
  --color-gainsboro-300: rgba(217, 217, 217, 0.1);
  --color-black: #000;
  --color-dodgerblue-100: #0095ff;
  --color-dodgerblue-200: rgba(0, 149, 255, 0.5);
  --color-darkslategray-100: #444;
  --color-darkslategray-200: #262b35;
  --color-ghostwhite: #f9f1ff;
  --color-goldenrod: #fec458;
  --color-darkturquoise: #00cec9;
  --color-whitesmoke: #eceff1;
  --color-midnightblue-100: #050038;
  --color-midnightblue-200: rgba(5, 0, 56, 0.6);
  --color-mediumslateblue: #0061ff;
  --color-silver: #bac1ce;
  --color-darkslateblue: #314CA3;
  --color-silver-100: #BAC1CE;
  --color-gray-400:#060606;
  

  /* Gaps */
  --gap-xl: 20px;
  --gap-9xl: 28px;
  --gap-base-3: 15.3px;
  --gap-261xl: 280px;
  --gap-2xl: 21px;
  --gap-11xl: 30px;
  --gap-41xl: 60px;
  --gap-53xl: 72px;
  --gap-19xl: 38px;
  --gap-42xl: 61px;
  --gap-lgi: 19px;
  --gap-8xs: 5px;
  --gap-mini: 15px;
  --gap-3xs: 10px;
  --gap-267xl: 286px;
  --gap-smi: 13px;
  --gap-lg: 18px;
  --gap-36xl: 55px;
  --gap-30xl: 49px;
  --gap-4xl: 23px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-10xs: 3px;
  --padding-2xs: 11px;
  --padding-sm: 14px;
  --padding-base: 16px;
  --padding-4xs-3: 8.3px;
  --padding-7xs-7: 5.7px;
  --padding-mini: 15px;
  --padding-54xl: 73px;
  --padding-74xl: 93px;
  --padding-51xl-1: 70.1px;
  --padding-67xl-8: 86.8px;
  --padding-280xl: 299px;
  --padding-xs: 12px;
  --padding-9xl: 28px;
  --padding-5xs: 8px;
  --padding-14xl: 33px;
  --padding-6xs: 7px;
  --padding-5xl: 24px;
  --padding-7xs: 6px;
  --padding-lgi: 19px;
  --padding-3xs: 10px;
  --padding-2xl: 21px;
  --padding-9xs: 4px;
  --padding-lg: 18px;
  --padding-smi: 13px;
  --padding-11xl: 30px;
  --padding-237xl: 256px;
  --padding-131xl: 150px;
  --padding-104xl: 123px;
  --padding-4xl: 23px;
  --padding-12xs-3: 0.3px;
  --padding-28xl: 47px;
  --padding-8xl: 27px;
  --padding-30xl: 49px;
  --padding-12xs-8: 0.8px;
  --padding-186xl: 205px;
  --padding-57xl: 76px;
  --padding-332xl: 351px;
  --padding-21xl: 40px;
  --padding-7xl: 26px;
  --padding-12xl: 31px;
  --padding-3xl: 22px;
  --padding-69xl: 88px;
  --padding-mid: 17px;
  --padding-11xs: 2px;

  /* Border radiuses */
  --br-101xl: 120px;
  --br-11xl: 30px;
  --br-xl: 20px;
  --br-mini: 15px;
  --br-6xs: 7px;
  --br-3xs: 10px;
  --br-81xl: 100px;
  --br-16xl: 35px;
  --br-32xl: 51px;
  --br-21xl: 40px;
  --br-30xl: 49px;
  --br-31xl-6: 50.6px;
  --br-45xl: 64px;
  --br-xs: 12px;
  --br-7xs: 6px;
  --br-5xs: 8px;
}
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400&display=swap");



*,
::before,
::after {
  border-width: 0;
}


:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-5xs: 8px;
  --font-size-3xs: 10px;

  /* Colors */
  --color-darkslategray: #313030;
}

:root {
  /* fonts */
  --heading-heading-3: Outfit;
  --body-body-1: "DM Sans";

  /* font sizes */
  --heading-heading-3-size: 9px;
  --body-body-1-size: 8px;
  --heading-heading-2-bold-size: 12px;
  --heading-heading-1-bold-size: 16px;

  /* Colors */
  --gray-lightest-2: #f7f9fc;
  --gray-dark: #47516b;
  --gray-default: #79829a;
  --gray-lightest: #e2e6ee;
  --gray-darker: #2e2e48;
  --white: #fff;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-13xl: 32px;
  --gap-5xs: 8px;
  --gap-11xs: 2px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-29xl: 48px;
  --padding-5xl: 24px;
  --padding-53xl: 72px;

  /* Border radiuses */
  --br-81xl: 100px;
}

/* temp7 */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&display=swap");

:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-5xs: 8px;
  --font-size-3xs: 10px;

  /* Colors */
  --color-darkslategray: #313030;
}

/* temp8 */
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,900;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

:root {
  /* fonts */
  --h2: "Source Serif Pro";
  --p-small:Inter;
  --font-sf-mono: "SF Mono";

  /* font sizes */
  --h2-size: 9px;
  --p-small-size: 8px;
  --h1-size: 18px;
  --font-size-xs: 12px;

  /* Colors */
  --color-whitesmoke: #ebebeb;
  --color-gray: #121212;

  /* Gaps */
  --gap-12xs: 1px;
  --gap-5xs: 8px;
  --gap-base: 16px;
  --gap-9xs: 4px;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

:root {
  /* fonts */
  --body-job-description: Inter;

  /* font sizes */
  --body-job-description-size: 12px;
  --caption-time-range-size: 10px;
  --heading-3-organization-size: 13px;
  --heading-2-size: 14px;
  --heading-1-size: 32px;

  /* Colors */
  --primary-background: #fff;
  --primary: #1f8c9e;
  --secondary-background: #fafafa;
  --text-secondary: #222;

  /* Gaps */
  --gap-base: 16px;
  --gap-7xs: 6px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-lg: 18px;
  --gap-12xs: 1px;

  /* Paddings */
  --padding-5xl: 24px;
  --padding-13xl: 32px;
  --padding-base: 16px;
}

/* temp12 */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap");
body {
  margin: 0;
  padding: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-space-mono: "Space Mono";
  --font-inter: Inter;

  /* font sizes */
  --font-size-mini: 15px;
  --font-size-base: 16px;

  /* Colors */
  --color-gray: #111118;
}


/* temp13 */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --heading-large-uppercase: Inter;

  /* font sizes */
  --heading-large-uppercase-size: 11px;
  --font-size-5xs: 8px;
  --name-size: 28px;
  --font-size-3xs: 10px;

  /* Colors */
  --color-whitesmoke: #eeefef;
  --color-gray-100: #1f1f1f;
  --accent: #121212;
  --color-royalblue: #0049ae;
  --color-darkslategray: #373737;

  /* Gaps */
  --gap-4xs: 9px;
  --gap-7xs: 6px;
  --gap-5xs: 8px;
  --gap-xl: 20px;
  --gap-11xl: 30px;
  --gap-3xs: 10px;

  /* Paddings */
  --padding-11xs: 2px;
  --padding-12xs: 1px;
  --padding-9xs: 4px;
  --padding-8xs: 5px;
}

/* temp14 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-xs: 12px;

  /* Colors */
  --neutrals-white: #fff;
  --color-midnightblue-100: #050f38;
  --color-darkslategray-100: #454552;
  --gray-800: #38383b;
}
/* temp1 */
body {
  margin: 0;
  
  line-height: normal;
}
:root {
 
  /* font sizes */
  --font-size-4xs: 9px;
  --font-size-2xs: 11px;
  --font-size-6xs: 7px;

  /* Colors */
  --color-gray: #101214;
  --color-slategray: #73808d;
}


/* temp2 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-5xs: 8px;
  --font-size-3xs: 10px;
  --font-size-xs: 12px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #878787;
  --color-gray-200: #2a2a2a;
  --color-gainsboro: #e5e5e5;
  --color-silver-100: #c4c4c4;
  --color-silver-200: #c4c2c2;
  --color-darkslategray: #233a5f;

  /* Gaps */
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-5xl: 24px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-9xs: 4px;
  --padding-smi: 13px;
  --padding-5xs: 8px;

  /* Border radiuses */
  --br-9xs: 4px;
}


/* temp3 */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@300;400;600;800&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --template-3-title-small: "Be Vietnam";

  /* font sizes */
  --template-3-title-small-size: 9.5px;
  --template-3-heading-small-size: 12px;
  --template-3-heading-big-size: 32px;

  /* Colors */
  --off-white: #fffdfd;
  --off-black: #1e1e1e;
  --grey: #d1d1d1;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-6xs: 7px;
  --gap-12xs: 1px;
  --gap-9xs: 4px;
  --gap-mini: 15px;

  /* Paddings */
  --padding-4xs: 9px;
}


/* temp4 */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --template-2-title-small: Lato;
  --template-2-heading-small: "Playfair Display";
  --template-1-title-small: Roboto;

  /* font sizes */
  --template-2-title-small-size: 10px;
  --template-2-heading-small-size: 12px;
  --template-1-title-small-size: 10px;
  --template-2-heading-big-size: 32px;

  /* Colors */
  --off-white: #fffdfd;
  --off-black: #1e1e1e;
  --grey: #d1d1d1;
  --light-grey: #f2f2f2;

  /* Gaps */
  --gap-21xl: 25px;
  --gap-3xs: 10px;
  --gap-6xs: 7px;
  --gap-12xs: 1px;
  --gap-9xs: 4px;
  --gap-mini: 15px;

  /* Paddings */
  --padding-4xs: 9px;
}


/* temp5 */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-regular: Inter;

  /* font sizes */
  --text-regular-size: 9px;
  --title-medium-size: 12px;
  --headline-size: 24px;
  --overline-semibold-size: 10px;
  --text-small-medium-size: 8px;

  /* Colors */
  --color-gainsboro: #e0e0e0;
  --neutral-600: #545b63;
  --neutral-400: #969da8;
  --color-darkslategray-100: #3f4549;
  --neutral-800: #2a2e30;
  --accent-green: #388f85;
  --neutral-500: #707880;

  /* Gaps */
  --gap-base: 16px;
  --gap-9xs: 4px;
  --gap-xl: 20px;
  --gap-5xs: 8px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-xs: 12px;
}


/* temp6 */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --style-3-skills: Raleway;
  --style-3-header-2: "Josefin Sans";

  /* font sizes */
  --style-3-skills-size: 11px;
  --style-3-header-2-size: 13px;
  --style-3-header-3-size: 10px;
  --style-3-year-city-size: 9px;
  --style-3-header-1-size: 25px;

  /* Colors */
  --colors-black: #212121;
}


/* temp7 */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto-light: "Roboto Light";
  --font-roboto-black: "Roboto Black";
  --font-bebas-neue: "Bebas Neue";
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-xs: 12px;
  --font-size-2xl: 21px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;

  /* Gaps */
  --gap-12xs: 1px;
  --gap-9xs-5: 3.5px;

  /* Paddings */
  --padding-xs: 12px;
  --padding-7xs: 6px;
  --padding-lg: 18px;
  --padding-10xs: 3px;
  --padding-base: 16px;
}


/* temp8 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prata:wght@400&display=swap");

:root {
  /* fonts */
  --font-poppins: Poppins;
  --label: Prata;

  /* font sizes */
  --font-size-3xs: 10px;
  --label-size: 11px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;

  /* Colors */
  --color-gray-100: #8b8b8b;
  --color-gray-200: #797979;
  --color-gray-300: #222;
  --color-cornflowerblue: #0e6cc2;
}


/* temp9 */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

:root {
  /* fonts */
  --h3: Inter;

  /* font sizes */
  --h3-size: 9.2px;
  --h2-size: 11.2px;
  --h1-size: 13.2px;

  /* Colors */
  --lighter: #646464;
  --darker: #000;

  /* Gaps */
  --gap-base: 16px;
  --gap-5xs: 12px;
  --gap-9xs: 4px;
}


/* temp10 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

:root {
  /* fonts */
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-5xs: 8px;
  --font-size-3xs: 10px;
  --font-size-xs: 12px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #878787;
  --color-gray-200: #2a2a2a;
  --color-gainsboro: #e5e5e5;
  --color-silver-100: #c4c4c4;
  --color-silver-200: #c4c2c2;
  --color-darkslategray: #233a5f;

  /* Gaps */
  --gap-xl: 20px;
  --gap-xs: 12px;
  --gap-5xl: 24px;
  --gap-9xs: 4px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-9xs: 4px;
  --padding-smi: 13px;
  --padding-5xs: 8px;
}


/* temp11 */

:root {
  /* fonts */
  --segment-text-link: Segment;

  /* font sizes */
  --segment-text-link-size: 10px;
  --segment-header-h3-size: 12px;
  --segment-text-bullet-size: 11px;
  --segment-text-subtext-size: 9px;
  --segment-text-regular-size: 16px;
  --segment-header-h1-size: 24px;

  /* Colors */
  --gray-background: #fff;
  --gray-secondary: #282828;
  --gray-primary: #101010;

  /* Gaps */
  --gap-base: 16px;
  --gap-9xs: 4px;
  --gap-11xs: 2px;
  --gap-5xs: 8px;
  --gap-12xs: 1px;

  /* Paddings */
  --padding-mini: 15px;
}


/* temp12 */
@import url("https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,300;0,500;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap");

:root {
  /* fonts */
  --body-light: Lato;
  --heading-3: Spectral;

  /* font sizes */
  --body-light-size: 10px;
  --heading-3-size: 14px;
  --heading-2-size: 16px;
  --heading-1-size: 30px;

  /* Colors */
  --white: #fff;
  --light-gray: #f7f8f8;
  --dark-gray: #ededed;
  --black: #242424;
  --midnight-blue: #2d3e5b;
  --pale-pink: #db9291;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-11xs: 2px;
  --gap-xs: 12px;

  /* Paddings */
  --padding-smi: 13px;
}


/* temp13 */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

:root {
  /* fonts */
  --h3: Inter;

  /* font sizes */
  --h3-size: 9.2px;
  --h2-size: 11.2px;
  --h1-size: 13.2px;

  /* Colors */
  --lighter: #646464;
  --darker: #000;

  /* Gaps */
  --gap-base: 16px;
  --gap-5xs: 12px;
  --gap-9xs: 4px;
}


/* temp14 */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prata:wght@400&display=swap");

:root {
  /* fonts */
  --font-poppins: Poppins;
  --label: Prata;

  /* font sizes */
  --font-size-3xs: 10px;
  --label-size: 11px;
  --font-size-sm: 14px;
  --font-size-xs: 12px;

  /* Colors */
  --color-gray-100: #797979;
  --color-gray-200: #222;
  --color-steelblue: #1c8fb5;
}


:root {
  /* fonts */
  --template-3-title-small: "Be Vietnam";

  /* font sizes */
  --template-3-title-small-size: 9.5px;
  --template-3-heading-small-size: 12px;
  --template-3-heading-big-size: 32px;

  /* Colors */
  --off-white: #fffdfd;
  --off-black: #1e1e1e;
  --grey: #d1d1d1;

  /* Gaps */
  --gap-3xs: 10px;
  --gap-6xs: 7px;
  --gap-12xs: 1px;
  --gap-9xs: 4px;
  --gap-mini: 15px;

  /* Paddings */
  --padding-4xs: 9px;
}
@media only screen and (max-width: 600px) {
  .scrollbar-hidden-personal::-webkit-scrollbar {
    display: none; /* Hide scrollbar for mobile devices */
  }
}

/* temp1 */

@import url('https://fonts.googleapis.com/css2?family=Brygada+1918:ital@1&display=swap');
body {
  margin: 0;
  padding: 0;
  line-height: normal;
}
:root {
  /* fonts */
  /* --font-space-mono: "Space Mono";
  --font-inter: Inter; */
  --font-Brygada-1918: "Brygada 1918";


  /* font sizes */
  --font-size-mini: 15px;
  --font-size-base: 16px;

  /* Colors */
  --color-gray: #111118;
}


/* temp2 */
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,400;0,500;1,400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-libre-franklin: "Libre Franklin";

  /* font sizes */
  --font-size-smi: 13px;
  --font-size-2xs: 11px;
  --font-size-4xs: 9px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;

  /* Border radiuses */
  --br-31xl: 50px;
}



