
  /* Desktop */
  @media screen and (min-width:1700px){
    .hamburger{
    display: none;
    }
   
  }
  /*  Laptop*/
@media screen and (max-width: 1700px) and (min-width: 1400px) {
    .hamburger{
        display: none;
        }
  }
   /* tablet */
  @media screen and (max-width: 1400px) and (min-width: 800px) {
   
    .abts{
        visibility: hidden;

    }
    .dashboards{
       /* display: none; */
       visibility: hidden;
        background-color: aqua;
    }
  }
  /* Mobile */
  @media screen and (max-width: 800px) {
  
    .dashboards{
        visibility: hidden;

    }
   .abts{
    visibility: hidden;
   
     }
  }
  