
.website-footer_all {
  position: relative;
  width: 100%;
  /* top: 1160px; */
  bottom: 0;
  right: -2px;
  left: 2px;
  background-color: var(--color-gray-900);
  height: 580px;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-gray-700);
  font-family: var(--font-dm-sans);
}
.content-container12_all {
  position: absolute;
  width: 100%;
  right: -2px;
  bottom: 1px;
  left: 2px;
  border-top: 1px solid var(--color-gainsboro-300);
  box-sizing: border-box;
  height: 610px;
}
.all-rights-reserved-parent_all {
  position: absolute;
  top: 88px;
  left: calc(50% - 654px);
  width: 1231.2px;
  height: 484px;
  overflow: hidden;
}
.all-rights-reserved1_all,
.item-link_all,
.item-link1_all,
.item-link2_all {
line-height: 30px;
font-weight: 500;
display: flex;
align-items: center;
height: 20px;
}
.all-rights-reserved1_all {
position: relative;
width: 219.6px;
flex-shrink: 0;
}
.all-rights-reserved_all {
  position: absolute;
  top: 420px;
  /* left: calc(50% - 608.6px); */
  left: 110px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-mini);
  gap: var(--gap-261xl);
}

.products-parent_all {
  position: absolute;
  top: 54px;
  left: 668px;
  width: 563.2px;
  height: 294px;
  overflow: hidden;
  font-size: 22.91px;
}
.products1_all {
  position: absolute;
  top: 0;
  left: calc(50% - 265.6px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9px;
}
.heading-67_all,
.item-link3_all {
position: relative;
display: flex;
align-items: center;
flex-shrink: 0;
}
.heading-67_all {
font-size: 22.73px;
line-height: 8.51px;
text-transform: uppercase;
font-weight: 600;
/* font-family: var(--font-montserrat-alternates); */
color: var(--color-black);
width: 160.4px;
height: 26.7px;
}
.item-link3_all {
  line-height: 45.07px;
  /* width: 225.3px; */
  height: 38.2px;
  font-size: 20px;
}
.heading-6-products-parent_all {
  position: absolute;
  top: 0;
  left: 272px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 9px;
}
.heading-68_all,
.item-link7_all {
position: relative;
display: flex;
align-items: center;
flex-shrink: 0;
}
.heading-68_all {
font-size: 22.73px;
line-height: 8.51px;
text-transform: uppercase;
font-weight: 600;
/* font-family: var(--font-montserrat-alternates); */
color: var(--color-black);
width: 190.9px;
height: 26.7px;
}
.item-link7_all {
  line-height: 45.07px;
  /* width: 252px; */
  height: 38.2px;
  font-size: 20px;
}
/* .heading-6-products-parent_all {
  position: absolute;
  top: 0;
  left: calc(50% + 29.6px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15.27px;
} */
.products-parent_all {
  position: absolute;
  top: 54px;
  left: 668px;
  width: 563.2px;
  height: 294px;
  overflow: hidden;
  font-size: 22.91px;
}
.facebooksvg-icon_all,
.follow_all {
  position: relative;
  flex-shrink: 0;
}
.follow_all {
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 55.6px;
  height: 19px;
}
.facebooksvg-icon_all {
  width: 19px;
  height: 18px;
  overflow: hidden;
}
.linkedinsvg-icon_all{
  width: 21px;
  height: 20px;
  overflow: hidden;

}
.instagramsvg-icon_all {
  position: relative;
  width: 16px;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
}
.twittersvg-icon_all {
  position: relative;
  width: 16px;
  height: 13.5px;
  overflow: hidden;
  flex-shrink: 0;
}

.item-link-linkedin_all,
.item-link-facebook_all,
.item-link-twitter_all,
.item-link-instagram_all  {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0.7;
}

.twittersvg-icon_all {
  position: relative;
  width: 16px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}


.item-link-facebooksvg_all {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0.7;
}
.pinterestsvg-icon_all {
  position: relative;
  width: 15.2px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.follow-us_all,
.list8_all {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.list8_all {
  align-items: flex-start;
  padding: 8.279999732971191px 0 5.720000267028809px;
  gap: var(--gap-9xl);
}
.follow-us_all {
  position: absolute;
  bottom: 120px;
  left: calc(50% - 600.6px);
  left: 110px;
  width: 265px;
  align-items: center;
  gap: var(--gap-xl);
  color: var(--color-black);
  font-family: var(--font-montserrat-alternates);
}
.recruiter-robo-logo-3-2-icon_all {
  position: relative;
  width: 59px;
  height: 69px;
  object-fit: cover;
}
.logo-container_all {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-base);
}
.is-a-dedicated_all {
  font-size: var(--font-size-mini);
  overflow-wrap: break-word;
  font-size: 13.5px;
}
.recruiter-robo-is-container_all {
  position: relative;
  line-height: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 267px;
  flex-shrink: 0;
}

.website-info-content_all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-10xs) var(--padding-2xs);
}
.logo-container-parent_all {
  position: absolute;
  top: 0;
  left: calc(50% - 615.6px);
  left: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-base);
  color: var(--color-gray-800);
}

.ball3svg-icon_all{
  max-width: 40%;
}
.green-ball-image-container1_all {
  position: absolute;
  top: 130px;
  left: calc(50% - 873.2px);
  left:215px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0;
}


.landing-page-desktop-19201_all {
  background-color: var(--color-white);
  overflow: hidden;
}
.landing-page-desktop-19201_all,
.new-landing-page-desktop-192_all {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.desktop-1520plus-made-on-1920_all {
  position: relative;
  width: 100%;
  height: 13463px;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-midnightblue-100);
  font-family: var(--font-roboto);
  zoom: 85%;
}
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(500px); /* Adjust the distance as needed */
  }
}

.yball_all {
  rotate: 0deg;
  float: right;
  margin: 200px 0 0 50px;
  width: 400px; /* Adjust the size as needed */
  height: 100px;
  animation: moveUpDown 6s linear infinite;
}
@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(500px); /* Adjust the distance as needed */
  }
}

 

.ball_all {
  rotate: 0deg;
  float: right;
  margin: 200px 0 0 50px;
  width: 400px; /* Adjust the size as needed */
  height: 100px;
  animation: moveUpDown 6s linear infinite;
}
.website-footer_all{
  overflow: hidden;
}

/* tablet View */
@media screen and (max-width: 1400px) and (min-width: 1222px){
  .website-footer_all{
    overflow: hidden;
  }
  .all-rights-reserved-parent_all{
    left:calc(50% - 650px);
  }
  .logo-container-parent_all{
    left:230px
  }
  .follow-us_all{
    left:240px
  }
  .all-rights-reserved_all {
    left:230px
  }
  .products-parent_all{
    left:690px
  }
  .heading-6-products-parent_all{
    left:200px
  }
 
  .products1_all,
  .heading-6-products-parent_all {
    gap: 0;
  }
  .item-link3_all,
  .item-link7_all {
    font-size: 15px;
    position: relative;
    bottom:5px
  } 
  .heading-67_all,
  .heading-68_all{
    font-size: 17px;
  }
  .is-a-dedicated_all {
    font-size: 12px;
  }
  .recruiter-robo-is-container_all {
    width: 221px;
  }
  
}
@media screen and (max-width: 1222px) and (min-width: 873px) {
  .website-footer_all{
    overflow: hidden;
  }
  .all-rights-reserved-parent_all{
    left:calc(50% - 650px);
  }
  .logo-container-parent_all{
    left:275px
  }
  .follow-us_all{
    left:285px
  }
  .all-rights-reserved_all {
    left:280px
  }
  .products-parent_all{
    left:652px
  }
  .heading-6-products-parent_all{
    left:200px
  }
 
  .products1_all,
  .heading-6-products-parent_all {
    gap: 0;
  }
  .item-link3_all,
  .item-link7_all {
    font-size: 15px;
    position: relative;
    bottom:5px
  } 
  .heading-67_all,
  .heading-68_all{
    font-size: 17px;
    /* color: red; */
  }
  .is-a-dedicated_all {
    font-size: 12px;
  }
  .recruiter-robo-is-container_all {
    width: 221px;
  }

}
/* laptop view */
@media screen and (max-width:1700px) and (min-width:1400px)
{
  .website-footer_all{
    overflow: hidden;
  }
 
  .all-rights-reserved-parent_all{
    left:calc(50% - 650px);
  }
  .logo-container-parent_all{
    left:155px
  }
  .follow-us_all{
    left:160px
  }
  
  .all-rights-reserved_all {
    left:148px
  }
  .products-parent_all{
    left:652px
  }
  .heading-6-products-parent_all{
    left:2
  }
  .social-media-button{
    width: 1rem;
    height: 1rem;
  }
  .item-link3_all,
  .item-link7_all {
    font-size: 17.5px;
  } 
  .products1_all,
  .heading-6-products-parent_all {
    gap: 4px;
  }
  .is-a-dedicated_all{
    font-size: 15px;
  }
  .heading-67_all,
  .heading-68_all{
    font-size: 20px;
  }
}
/* Desktop view */
@media screen and (min-width:1700px){


}

@media screen and (max-width:800px){
  .website-footer_all{
    overflow: hidden;
    height: 538px;
    /* margin-top: 200px; */
  }
  .all-rights-reserved-parent_all{
    left:calc(50% - 650px);
  }
  .logo-container-parent_all{
    left:275px;
    display:none;
  }
  .follow-us_all{
    left:490px;
    top:350px
 }
  .all-rights-reserved_all {
    left:540px
  }
  .products-parent_all{
    left:490px;
    gap:100px
  }
  .heading-6-products-parent_all{
    left:250px
  }
 
  .products1_all,
  .heading-6-products-parent_all {
    gap: 0;
    width: 120px;
  }
  .item-link3_all,
  .item-link7_all {
    font-size: 15px;
    position: relative;
    bottom:5px
  } 
  .heading-67_all,
  .heading-68_all{
    font-size: 17px;
    /* color: red; */
  }
  .is-a-dedicated_all {
    font-size: 12px;
  }
  .recruiter-robo-is-container_all {
    width: 221px;
  }

  
  .website-footer_all{
    overflow: hidden;
  }
}