.main-container {
  height: 85vh;
  overflow: auto;
  resize: none;
}
.bg-3 {
  overflow: hidden;
}
.main-container::-webkit-scrollbar {
  width: 4px;
  scroll-margin-top: 8px;
  border-radius: 10px;
}
.main-container::-webkit-scrollbar-thumb {
  background-color: #438ffe;
}
