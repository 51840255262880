.start-interview {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.start-interview .group-wrapper {
  height: 378px;
  overflow-x: visible;
  width: 886px;
}

.start-interview .group {
  height: 378px;
  width: 888px;
}

.start-interview .overlap {
  background-color: #ffffff;
  border-radius: 25px;
  height: 378px;
  position: relative;
  
}

.start-interview .what-type-of {
  height: 289px;
  left: 57px;
  position: absolute;
  top: 89px;
  width: 308px;
}

.start-interview .listbox-title-instance {
  left: 14px !important;
  position: absolute !important;
  top: 0 !important;
  width: 280px !important;
}

.start-interview .overlap-group {
  height: 60px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 308px;
}

.start-interview .listbox-main {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 14px #0000001a;
  height: 60px;
  left: 14px;
  position: absolute;
  top: 0;
  width: 280px;
}

.start-interview .chevron {
  height: 6px;
  left: 247px;
  position: absolute;
  top: 27px;
  width: 11px;
}

.start-interview .placeholder-text-instance {
  left: 16px !important;
  position: absolute !important;
  top: 20px !important;
}

.start-interview .clip-list {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 308px;
}

.start-interview .overlap-2 {
  height: 261px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 308px;
}

.start-interview .clip-list-2 {
  height: 261px;
  left: 0;
  position: absolute;
  top: 0;
  width: 308px;
}

.start-interview .save-button {
  all: unset;
  align-items: center;
  background-color: #314ca3;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  left: 144px;
  overflow:visible;
  padding: 15px 77px;
  position: absolute;
  top: 210px;
  width: 150px;
}
.getadvice-button {
  align-items: center;
  background-color: #314ca3;
  border-radius: 15px;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  /* left: 624px; */
  overflow: hidden;
  padding: 15px 77px;
  /* position: absolute; */
  /* top: 900px; */
  width: 150px;
  position: relative;
  left: 278px;
  top: 30px;
  
  
}

.start-interview .save {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.5px;
  margin-left: -23.5px;
  margin-right: -23.5px;
  margin-top: -4.5px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.start-interview .text-wrapper {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 500;
  left: 71px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 25px;
}

.index4 {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.index4 .div {
  background-color: #ffffff;
  height: 617px;
  position: relative;
  width: 820px;
}

.index4 .frame {
  border: 1px solid;
  border-color: #e3e4e8;
  border-radius: 15px;
  height: 49px;
  left: 56px;
  overflow: hidden;
  position: absolute;
  top: 537px;
  width: 709px;
}

.index4 .text-wrapper {
  color: #888ea2 !important;
  font-family: "Inter", Helvetica;
  font-size: 15px !important;
  font-weight: 400 !important;
  left: 1px !important;
  letter-spacing: 0 !important;
  line-height: normal;
  position: absolute;
  white-space: wrap ;
  border: none;
  top: 1px !important;
  border-radius: 15px !important;
  height: 45px !important;
  width: 705px;
}

.index4 .mingcute-send-line {
  height: 24px;
  left: 668px;
  position: absolute;
  top: 13px;
  width: 24px;
}

.index4 .group {
  height: 26px;
  left: 56px;
  position: absolute;
  top: 103px;
  width: 57px;
  
}

.index4 .text-wrapper-2 {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 700;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 7px;
  white-space: wrap;
}

.index4 .profile-picture {
  align-items: flex-start;
  display: inline-flex;
  gap: 4.33px;
  left: 0;
  position: absolute;
  top: 0;
}

.index4 .ellipse {
  height: 26px;
  object-fit: cover;
  position: relative;
  width: 26px;
}

.index4 .group-2 {
  height: 24px;
  left: 56px;
  position: absolute;
  top: 42px;
  width: 112px;
}

.index4 .text-wrapper-3 {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 700;
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 6px;
  white-space: wrap;
}

.index4 .ri-robot-line {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.index4 .group-3 {
  height: 24px;
  left: 56px;
  position: absolute;
  top: 166px;
  width: 112px;
}

.index4 .scroll-bar {
  background-color: #438ffe;
  border-radius: 49px;
  height: 200px;
  left: 815px;
  position: absolute;
  top: 168px;
  transform: rotate(180deg);
  width: 5px;
}

.index4 .your-name {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 500;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 138px;
  width: 100vw !important;
  /* background-color: red; */
}

.index4 .p {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 500;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 75px;
  /* background-color: red; */
}

.index4 .your-name-2 {
  color: #888ea2;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 500;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 199px;
  width: 100vw !important;
  /* background-color: red; */
}
.loaderAudio {
  height: 100%;
  background-color:#fff;
}

#wave {
  height: 70px;
  width: 70px;
  fill: #000;
}

#Line_1 {
  animation: pulse 1s infinite;
  animation-delay: 0.15s;
}

#Line_2 {
  animation: pulse 1s infinite;
  animation-delay: 0.3s;
}

#Line_3 {
  animation: pulse 1s infinite;
  animation-delay: 0.45s;
}

#Line_4 {
  animation: pulse 1s infinite;
  animation-delay: 0.6s;
}

#Line_5 {
  animation: pulse 1s infinite;
  animation-delay: 0.75s;
}

#Line_6 {
  animation: pulse 1s infinite;
  animation-delay: 0.9s;
}

#Line_7 {
  animation: pulse 1s infinite;
  animation-delay: 1.05s;
}

#Line_8 {
  animation: pulse 1s infinite;
  animation-delay: 1.2s;
}

#Line_9 {
  animation: pulse 1s infinite;
  animation-delay: 1.35s;
}

@keyframes pulse {
  0% {
      transform: scaleY(1);
      transform-origin: 50% 50%;
  }
  
  50% {
      transform: scaleY(0.7);
      transform-origin: 50% 50%;
  }
  
  100% {
      transform: scaleY(1);
      transform-origin: 50% 50%;
  }
}