progress[value]::-webkit-progress-bar {
  overflow: hidden;
  background-color: #eeeeee;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.job-submitted {
  background-color: #f4f6f9;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.job-submitted .div {
  background-color: #f4f6f9;
  height: 372px;
  position: relative;
  width: 986px;
}

.job-submitted .your-job-is-posted {
  color: #00000080;
  font-family: "Poppins-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 211px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 30px;
  width: 632px;
}

.job-submitted .frame {
  background-color: #438ffe;
  border: 1px solid;
  border-color: #9a6aff;
  border-radius: 15px;
  height: 50px;
  left: 211px;
  position: absolute;
  top: 277px;
  width: 220px;
}

.job-submitted .text-wrapper {
  color: #ffffff;
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 75px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
  white-space: wrap;
}

.job-submitted .div-wrapper {
  background-color: #438ffe;
  border: 1px solid;
  border-color: #9a6aff;
  border-radius: 15px;
  height: 50px;
  left: 607px;
  position: absolute;
  top: 277px;
  width: 220px;
}

.job-submitted .text-wrapper-2 {
  color: #ffffff;
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 88px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 12px;
  white-space: wrap;
}