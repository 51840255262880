.bg2 {
  background-color: #438ffe;
  color: #438ffe;
}

.bg-3 {
  overflow: hidden;
}
.text2 {
  color: #438ffe;
}
.text3 {
  color: #d9d9d9;
}

.main-container {
  height: 85vh;
  overflow: auto;
  resize: none;
}

.main-container::-webkit-scrollbar {
  width: 4px;
  scroll-margin-top: 8px;
  border-radius: 10px;
}
.main-container::-webkit-scrollbar-thumb {
  background-color: #438ffe;
}
